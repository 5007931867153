<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <v-card-title class='ml-2'>Edit Case# {{$route.params.id}}</v-card-title>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading case data.....
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CaseForm
      @finishLoading='dialog = false;'
      v-if='result'
      :editMode='true'
      :editcaseObj='result'
    />
  </div>
</template>

<script>
import { getCase } from "../dataservices/caseDataservices";
import CaseForm from "../components/CaseForm.vue";
export default {
  components: {
    CaseForm,
  },
  async mounted() {
    this.dialog = true;
    let ref = await getCase(this.$route.params.id);
    ref.onSnapshot((docs) => {
      this.result = docs.docs.map((doc) => doc.data())[0];
   
    });
  },
  data() {
    return {
      dialog: false,
      fileURL: null,
      result: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Cases",
          disabled: false,
          href: "/cases/all",
        },
        {
          text: `Case ${this.$route.params.id}`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>