var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":"/","large":""}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm delete this profile? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Agree ")])],1)],1)],1),_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary","dark":"","to":"/newHelper"}},[_vm._v("New Domestic Helper ")]),_c('v-btn',{staticClass:"ml-4 mb-5",attrs:{"dark":""},on:{"click":_vm.exportAll}},[_vm._v("Export All Data ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRule,"label":"Search"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-col',[_c('v-select',{attrs:{"rules":_vm.requiredRule,"item-value":"value","item-title":"text","label":"Search Type","items":[{
        text : 'Name', value : 'name'
      },{
        text : 'Phone Number', value : 'phoneNumber'
      },{
        text : 'Profile Id', value : 'portfolioId'
      }]},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchHandler}},[_vm._v(" Search ")]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.clearSearchbar}},[_vm._v(" Clear ")])],1)],1)],1),_c('h2',[_vm._v("Domestic Helpers")]),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{staticClass:"ma-5",attrs:{"fab":"","small":"","color":"primary","disabled":_vm.page === 1},on:{"click":function($event){return _vm.fetchPage('left')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-left ")])],1),_vm._v(" Next 150 Records "),_c('v-btn',{staticClass:"ma-5",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.fetchPage('right')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right ")])],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Global Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"search":_vm.search,"hide-default-footer":"","loading-text":"Loading data... Please wait","headers":_vm.headers,"items":_vm.result,"items-per-page":150},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLink(item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.skills",fn:function(ref){
      var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.careInfant >= 3 ? "Newborn" : ""))]),_c('p',[_vm._v(_vm._s(item.careChild >= 3 ? "Child" : ""))]),_c('p',[_vm._v(_vm._s(item.careElderly >= 3 ? "Elderly" : ""))])]}},{key:"item.workexps",fn:function(ref){
      var item = ref.item;
return _vm._l((item.workExp),function(e,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(e.country)+" : "+_vm._s(e.duration)+" ")])})}},{key:"item.image",fn:function(ref){
      var item = ref.item;
return [(item.fileLocation.includes("/null"))?_c('img',{attrs:{"src":require("../assets/notyet.jpg"),"width":"80"}}):_c('img',{attrs:{"src":("https://storage.googleapis.com/starjasmine-aacba.appspot.com/" + (item.fileLocation)),"width":"80"}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{staticClass:"ma-5",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.fetchPage('left')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-left ")])],1),_c('v-btn',{staticClass:"ma-5",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.fetchPage('right')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }