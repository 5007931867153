import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
const firebaseConfig = {
	apiKey: "AIzaSyCmZm2I2TYSKbDzTkjKyg5PQsmqMMZDuwA",
	authDomain: "starjasmine-aacba.firebaseapp.com",
	projectId: "starjasmine-aacba",
	storageBucket: "starjasmine-aacba.appspot.com",
	messagingSenderId: "34439383971",
	appId: "1:34439383971:web:bd5ceffb9e92d7f13ba816",
};

const admin = firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
const auth = admin.auth("starjasmine-aacba.appspot.com");
const db = admin.firestore();
const { currentUser } = admin;

// firebase collections

export { storage, auth, currentUser, db, firebase };
