import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#4cb7b7",
				secondary: "#fe7e6d",
				accent: "#8c9eff",
				error: "#b71c1c",
			},
		},
	},
});
