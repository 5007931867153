<template>
  <div>

    <v-card flat>
      <v-card-title>
        Requested Interviews with Maid
        <v-spacer></v-spacer>

      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="interviewData"
      >
      <template v-slot:item.caseId="{ item }">
          <a @click='openLink(item.caseId,"case")'>{{item.caseId}}</a>
        </template>
        <template v-slot:item.id="{ item }">
          <a @click='openLink(item.id,"id")'>{{item.id}}</a>
        </template>
        <template v-slot:item.maidPortfolioId="{ item }">
          <a @click='openLink(item.maidId,"maid")'>{{item.maidPortfolioId}}</a>
        </template>
        <template v-slot:item.meetinglink="{ item }">
          <v-btn
            :disabled='item.status !== "scheduled"'
            color='green'
            icon
            small
            @click='openLink(item.meetingLink,"meeting")'
          ><v-icon>mdi-video-account</v-icon></v-btn>
        </template>
         <template v-slot:item.action="{ item }">
          <v-btn
            :disabled='item.status === "completed" || item.status === "canceled"'
            small
            :loading='cancelLoading'
            @click='cancelInterview(item.id)'
          >Cancel</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {requestCancelInterview} from "../dataservices/interviewDataservices"
export default {
  props: {
    interviewData: Array,
    headers: Array,
  },
  mounted() {},
  methods: {
    async cancelInterview(id){
      this.cancelLoading = true;
      await requestCancelInterview(id);
      this.cancelLoading = false;
      alert("interview canceled.")
    },
    openLink(id, type) {
      let routeData;
      if (type === "maid") {
        routeData = this.$router.resolve({ name: `/helpers/${id}` });
      } 
      else if (type === "case") {
        routeData = this.$router.resolve({ name: `/case/${id}` });
      } 
       else if (type === "meeting") {
        window.open("https://starjasmine-aacba.web.app/meeting/"+id,'_blank');
      } 
      else {
        routeData = this.$router.resolve({ name: `/admin/interview/${id}` });
      }

      window.open(routeData.route.name, "_blank");
    },
  },
  data() {
    return {
      cancelLoading : false,
      selectedMaid: null,
      interviewTimeDate: null,
      requestdialog: false,
      data: [],
    };
  },
};
</script>

<style>
</style>