import { db } from "../firebase/firebaseConfig";
import store from "../store/index";

export const getInterviewById = async (id) => {
	return db.collection("invterviews").where("id", "==", id);
};

export const getAllInterviews = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db.collection("invterviews").orderBy("createTime", "desc");
	} else {
		return db
			.collection("invterviews")
			.where("franchiseeId", "==", claims.franchiseeId)
			.orderBy("createTime", "desc");
	}
};

export const getAllInterviewByCaseId = async (caseId) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db
			.collection("invterviews")
			.where("caseId", "==", caseId)
			.orderBy("createTime", "desc");
	} else {
		return db
			.collection("invterviews")
			.where("status", "!=", "canceled")
			.where("franchiseeId", "==", claims.franchiseeId)
			.where("caseId", "==", caseId)
			.orderBy("status", "desc")
			.orderBy("createTime", "desc");
	}
};

export const updateInterviewData = async (id, meetingLink) => {
	return await db.collection("invterviews").doc(id).set(
		{
			meetingLink: meetingLink,
			status: "scheduled",
		},
		{ merge: true }
	);
};

export const requestCancelInterview = async (id) => {
	return await db.collection("invterviews").doc(id).set(
		{
			status: "canceled",
		},
		{ merge: true }
	);
};

export const createNewInterview = async (obj) => {
	const claims = store.getters.claims;
	if (claims.role !== "admin") {
		obj.franchiseeId = claims.franchiseeId;
		obj.franchiseeCode = claims.franchiseeCode;
	}
	let ref = db.collection("invterviews").doc();
	obj.id = ref.id;
	return await db.collection("invterviews").doc(ref.id).set(obj);
};
