<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <v-card-title class='ml-2'>New Case</v-card-title>

    <CaseForm :editMode='false' />
  </div>
</template>

<script>
import CaseForm from "../components/CaseForm.vue";
export default {
  components: {
    CaseForm,
  },
  async mounted() {},
  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Cases",
          disabled: false,
          href: "/cases/all",
        },
        {
          text: `New Case`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>