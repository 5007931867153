<template>
  <v-btn
    :loading='unholdLoading'
    @click='unHoldMaid'
    :disabled='disabled'
  >
    Unhold
  </v-btn>
</template>

<script>
import { db } from "../firebase/firebaseConfig";

export default {
  props: {
    caseId: String,
    heldMaid: String,
    disabled: Boolean,
  },
  mounted(){
    console.log(this.heldMaid)
  },
  methods: {
    async unHoldMaid() {
      this.unholdLoading = true;
      await db.collection("cases").doc(this.caseId).update({
        heldMaid: null,
        heldMaidTime: null,
        caseStatus: "Looking for Helper",
      });
      await db.collection("Helpers").doc(this.heldMaid).update({
          caseId : null,
        })
      this.unholdLoading = false;
      this.$emit("onComplete");
    },
  },
  data() {
    return {
      unholdLoading: false,
    };
  },
};
</script>

<style>
</style>