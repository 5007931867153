var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Requested Interviews with Maid "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.interviewData},scopedSlots:_vm._u([{key:"item.caseId",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLink(item.caseId,"case")}}},[_vm._v(_vm._s(item.caseId))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLink(item.id,"id")}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.maidPortfolioId",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLink(item.maidId,"maid")}}},[_vm._v(_vm._s(item.maidPortfolioId))])]}},{key:"item.meetinglink",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.status !== "scheduled","color":"green","icon":"","small":""},on:{"click":function($event){return _vm.openLink(item.meetingLink,"meeting")}}},[_c('v-icon',[_vm._v("mdi-video-account")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.status === "completed" || item.status === "canceled","small":"","loading":_vm.cancelLoading},on:{"click":function($event){return _vm.cancelInterview(item.id)}}},[_vm._v("Cancel")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }