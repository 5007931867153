var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm delete this case? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog2 = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Agree ")])],1)],1)],1),_c('v-breadcrumbs',{attrs:{"items":_vm.items,"divider":"/","large":""}}),_c('v-container',[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary","to":"/cases/new"}},[_vm._v("+ New Case")]),_c('h2',[_vm._v("All Cases")]),_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading data... Please wait","headers":_vm.headers,"items":_vm.result,"search":_vm.search,"multi-sort":""},scopedSlots:_vm._u([{key:"item.bio.franchiseeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFranchiseeID(item.bio.franchiseeId))+" ")]}},{key:"item.bio.agentId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAgentID(item.bio.agentId))+" ")]}},{key:"item.caseStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getColor(item.caseStatus)}},[_vm._v(" "+_vm._s(item.caseStatus)+" ")])]}},{key:"item.caseCreatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCreatedAt(item.caseCreatedAt)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.caseNumber",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openLink(item.caseNumber)}}},[_vm._v(_vm._s(item.caseNumber))])]}},{key:"item.requests[0].adjustedRequest.age",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.requests[0].adjustedRequest.age[0])+" to "+_vm._s(item.requests[0].adjustedRequest.age[1]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }