<template>
  <v-app>
    <v-main>
      <v-app-bar
        flat
        color="transparent"
        elevation="0"
      >
        <v-spacer></v-spacer>
        <v-btn
          v-if='user.loggedIn'
          text
          @click='darkModeOn'
          icon
        >
          <v-icon>mdi-lightbulb-night</v-icon>
        </v-btn>
        <v-btn
          v-if='user.loggedIn'
          text
          @click='logout'
        >
          <span class="mr-2">Logout</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        floating
        permanent
        v-if='user.loggedIn'
        v-model="drawer"
        app
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2 mt-5"
                contain
                :src="`data:image/png;base64,${franImg}`"
                transition="scale-transition"
                width="250"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div class='ma-10'></div>
        <v-list
          shaped
          nav
        >
          <h3
            class='mb-5'
            v-if='user.data'
          >Welcome, {{user.data.displayName}}</h3>
          <v-list>

            <div
              :key="item.title"
              v-for="item in items"
            >
              <v-list-group
                v-if='item.items'
                v-model="item.active"
                :prepend-icon="item.icon"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  link
                  v-for="child in item.items"
                  :key="child.title"
                  :to='child.to'
                >

                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :to='item.to'
              >
                <v-list-item-icon>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{item.title}}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-list>
      </v-navigation-drawer>

      <v-container v-if='!$route.meta.useContainer'>

        <router-view />

      </v-container>

      <router-view v-else />
    </v-main>

  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapFields } from "vuex-map-fields";
import { getFranImg } from "./dataservices/regDataservices";
export default {
  computed: {
    ...mapFields(["user", "claims", "items"]),
  },
  name: "App",
  methods: {
    darkModeOn() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    },
  },
  async mounted() {
    this.franImg = await getFranImg(this.claims.franchiseeCode);
  },
  data: () => ({
    franImg: "",
    drawer: null,
    loggedin: false,
  }),
};
</script>
