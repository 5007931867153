<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <HelperForm
      v-if='result'
      :dataFromDB='result'
      mode='application'
    />

  </div>
</template>

<script>
import HelperForm from "../components/HelperForm.vue";
import { db } from "../firebase/firebaseConfig";

export default {
  components: {
    HelperForm,
  },
  async mounted() {
    db.collection("register")
      .where("id", "==", this.$route.params.id)
      .onSnapshot((docs) => {
        this.result = docs.docs.map((doc) => doc.data())[0];
      });
  },
  data() {
    return {
      fileURL: null,
      result: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Domestic Helpers Application",
          disabled: false,
          href: "/helper/all",
        },
        {
          text: `Application ${this.$route.params.id}`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>