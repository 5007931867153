export const maidData = () => {
    return {
        id : null,
        submitDate : null,
        valid : true,
        availabilityDate : null,
        name : null,
        dob : null,
        age : 0,
        nationality : null,
        education : null,
        maritalStatus : null,
        religion : null,
        height : 0,
        weight : 0,
        pic : null,
        videoLink : null,
        briefInfo : null,
        pork : false,
        acceptNoSundayOff : false,
        roomShare : false,
        afraidPet : false,
        smoke : false,
        alcohol : false,
        illnessesSurgery : false,
        cantonese : 0,
        english : 0,
        mandarin : 0,
        cleanliness : 0,
        discipline: 0,
        empathy: 0,
        efficiency: 0,
        friendliness: 0,
        hardworking: 0,
        preferredfamilysize : 0,
        honesty: 0,
        patience: 0,
        proactiveness: 0,
        responsible: 0,
        careInfant : 0,
        careChild : 0,
        careElderly : 0,
        careDisabled: 0,
        careBedridden : 0,
        carePet : 0,
        cooking : 0,
        generalHousework : 0,
        carWashing : 0,
        workExp : [],
    }
}