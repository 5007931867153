<template>

  <div>

    <v-dialog
      v-model="dialog2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{editMode ? "Edit Invoice" : "New Invoice"}}
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            class='mt-5'
          >
            <v-row>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyEngName'
                  label="Company English Name"
                  required
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyChineseName'
                  label="Company Chinese Name"
                  required
                ></v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyAddressEng'
                  label="Company Address (Eng)"
                  required
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyAddressChinese'
                  label="Company Address (Chinese)"
                  required
                ></v-text-field>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyPhoneNumber'
                  label="Company Phone Number"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.companyEmail'
                  label="Company Email"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.date'
                  label="Date"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.customerName'
                  label="Customer Name"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.maidName'
                  label="Maid Name"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.startDate'
                  label="Maid Start Date"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.baseFee'
                  label="Agency Fee"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.otherFee'
                  label="Other Fee"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.totalFee'
                  label="Total Fee"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.paidAmount'
                  :rules='requiredRule'
                  label="Paid Amount"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  :rules='requiredRule'
                  :items='["Cash","Cheque","Other"]'
                  v-model='form.paymentMethod'
                  label="Payment Method"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :rules='requiredRule'
                  v-model='form.OutstandingBalance'
                  label="Outstanding Balance"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.RepName'
                  label="Rep Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.RepPosition'
                  label="Rep Position"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model='form.signDate'
                  label="Sign Date"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  :disabled="editMode"
                  v-model='form.invoiceNumber'
                  label="Invoice Number"
                  :rules='requiredRule'
                  required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading='saveLoading'
            @click="save"
          >
            {{editMode ? "Edit" : "Save"}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
    >

      <v-card v-if='caseObj'>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Invoice</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

        <v-container>

          <v-card-title>
            <v-btn
              @click="newInvoice"
              class="primary"
            >Add Invoice</v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="invoiceData"
            :search="search"
          >

            <template v-slot:item.paidAmount="{ item }">
              <span>${{item.paidAmount}}</span>
            </template>

            <template v-slot:item.OutstandingBalance="{ item }">
              <span>${{item.OutstandingBalance}}</span>
            </template>

            <template v-slot:item.action="{ item }">

              <v-icon
                class='ml-4'
                @click='print(item)'
              >
                mdi-printer
              </v-icon>

              <v-icon
                class='ml-4'
                @click='edit(item)'
              >
                mdi-pencil
              </v-icon>

              <v-icon
                class='ml-4'
                @click='deleteItem(item)'
              >
                mdi-delete
              </v-icon>
            </template>

          </v-data-table>

          <div
            id='printMe'
            style='visibility: hidden;'
          >
            <p style="margin-top : 35px ; text-align: center;">{{form.companyEngName}} <br />{{form.companyAddressEng}}<br />Tel 電話: {{form.companyPhoneNumber}} Email : {{form.companyEmail}}</p>

            <p style="text-align: center;"><strong>Receipt from EA&nbsp; -&nbsp; to FDH Employer</strong><br /><strong>職業介紹所收據&nbsp; -&nbsp; 發給外傭僱主</strong></p>

            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Date日期: {{form.date}}</td>
                  </tr>
                  <tr>
                    <td>Name of Employer僱主姓名: {{form.customerName}}</td>
                  </tr>
                  <tr>
                    <td>Name of Foreign Domestic Helper外傭姓名: {{form.maidName}}</td>
                  </tr>
                  <tr>
                    <td>Expected Date of Reporting Duty預計到職日期: {{form.startDate}}</td>
                  </tr>
                </tbody>
                <br />
              </table>
            </div>
            <div>
              <table style='border-collapse: collapse;'>
                <tbody>
                  <tr>
                    <td
                      colspan="2"
                      style='border-bottom : 1px solid ; border-top : 1px solid ; '
                    >Agency fee for employing FDH(Please refer to the Service Agreement for the service included in the agency fee)聘請外傭費用（請參閱「服務協議」內列明的收費及所包括的服務）</td>
                    <td style='border-top : 1px solid ;  border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>${{form.baseFee}}</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style='border-bottom : 1px solid'
                    >Other charges (if applicable)其他費用（如適用）</td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>{{form.otherFee}}</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style='border-bottom : 1px solid'
                    >Total&nbsp; 合計</td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>${{form.totalFee}}</td>
                  </tr>
                  <tr>
                    <td style='border-bottom : 1px solid'>Paid amount&nbsp; 已付金額</td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>

                      <span>{{form.paymentMethod === "Cash" ? "✔" : "⬜"}}Cash 現金<br /></span>
                      <span>{{form.paymentMethod === "Cheque" ? "✔" : "⬜"}}Cheque 支票<br /></span>
                      <span> {{form.paymentMethod === "Other" ? "✔" : "⬜"}}Other 其他:FPS<br /></span>

                    </td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>${{form.paidAmount}}</td>
                  </tr>
                  <tr>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>Outstanding balance 尚欠金額</td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>&nbsp;</td>
                    <td style='border-bottom : 1px solid; border-left : 1px solid; border-right : 1px solid;'>${{form.OutstandingBalance}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <br />
              <p>Service Agreement has been provided to the employer已向僱主提供「服務協議」</p>
            </div>

            <table style='margin-top : 100px; border-collapse: collapse;'>
              <tbody>
                <tr>

                  <td>____________________________________</td>
                  <td style='width :19%'></td>
                  <td>_______________________________________</td>
                </tr>
                <tr>

                  <td style="text-align:center;">Signature of Employment Agency Representative <br />職業介紹所代表簽署
                  </td>
                  <td></td>
                  <td style="text-align:center;">Company Chop of Employment Agency <br />職業介紹所的公司印鑑
                  </td>
                </tr>
                <tr>
                  <td>( Name姓名：{{form.RepName}} )</td>
                </tr>

                <tr>
                  <td>( Position職位：{{form.RepPosition}} )</td>
                </tr>

                <tr>
                  <td>Date日期：{{form.signDate}}</td>
                </tr>

              </tbody>
            </table>

            <p>Note 1: <br />註1:</p>

            <p>According to EO and EAR, the maximum commission which may be received by an EA from a job-seeker shall be an amount not exceeding a sum equal to 10% of the first-month&rsquo;s wages received by the job-seeker for each employment that he/she has been successfully placed in employment by the EA. The provisions are applicable to <span style="text-decoration: underline;"><strong>all</strong></span> job-seekers. 根據《僱傭條例》及《職業介紹所規例》，職業介紹所向求職者收取的佣金最高限額，就每一宗成功介紹而言，不得多於求職者覓得職位後收取的第一個月工資的百分之十。此規定適用於<span style="text-decoration: underline;"><strong>所有</strong></span>求職者。</p>
            <p>For items with &ldquo;⬜&rdquo;, please &ldquo;✔&rdquo; as appropriate&nbsp;</p>
            <p>請在適當方格內填上「✔」號</p>
          </div>

        </v-container>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {
  createInvoice,
  editInvoice,
  deleteInvoice,
  geInvoiceByCaseId,
} from "../dataservices/caseDataservices";
import { getFranchiseeById } from "../dataservices/adminDataservices";
import moment from "moment";
export default {
  props: {
    dialog: Boolean,
    caseObj: Object,
    maidObj: Object,
  },

  async mounted() {
    this.franData = await getFranchiseeById(this.caseObj.bio.franchiseeId);
    let query = await geInvoiceByCaseId(this.caseObj.id);

    query.onSnapshot((docs) => {
      this.invoiceData = docs.docs.map((doc) => doc.data());
    });

    this.form.companyEmail = this.franData.email;
    this.form.companyPhoneNumber = this.franData.phoneNumber;
    this.form.companyEngName = this.franData.name;
    this.form.companyAddressEng = this.franData.address;

    if (this.caseObj) {
      this.form.customerName = `${this.caseObj.bio.customerLastName}, ${this.caseObj.bio.customerMiddleName} ${this.caseObj.bio.customerFirstName}`;
      this.form.baseFee = this.caseObj.agencyFee;
      this.form.totalFee = this.caseObj.agencyFee;
    }

    if (this.maidObj) {
      this.form.maidName = `${this.maidObj.LastNameOnIDPassport}, ${this.maidObj.MiddleNameOnIDPassport} ${this.maidObj.FirstNameOnIDPassport}`;
    }
  },
  data() {
    return {
      editMode: false,
      valid: true,
      requiredRule: [(v) => !!v || "This Field is required"],
      dialog2: false,
      search: null,
      headers: [
        { text: "Invoice Number", value: "invoiceNumber" },
        { text: "create Date", value: "createDate" },
        { text: "Paid Amount Date", value: "paidAmount" },
        { text: "Outstanding Balance", value: "OutstandingBalance" },
        { text: "Action", value: "action" },
      ],
      saveLoading: false,
      invoiceData: [],
      showPrint: false,
      form: {
        invoiceNumber: "",
        companyEngName: "",
        companyChineseName: "",
        companyAddressChinese: "",
        companyAddressEng: "",
        companyPhoneNumber: "",
        companyEmail: "",
        date: moment().format("DD/MM/YYYY"),
        customerName: "",
        maidName: "",
        startDate: "",
        baseFee: "",
        otherFee: "N/A",
        totalFee: "",
        paidAmount: "",
        paymentMethod: "",
        OutstandingBalance: "",
        RepName: " ",
        RepPosition: " ",
        signDate: moment().format("DD/MM/YYYY"),
      },
      franData: {
        name: "",
        email: "",
        phoneNumber: "",
        address: "",
      },
      menu: false,
    };
  },
  watch: {
    itemRows() {
      this.calcTotal();
    },
  },
  methods: {
    newInvoice() {
      let newForm = {
        invoiceNumber: "",
        date: moment().format("DD/MM/YYYY"),
        startDate: "",
        otherFee: "N/A",
        totalFee: "",
        paidAmount: "",
        paymentMethod: "",
        OutstandingBalance: "",
        RepName: " ",
        RepPosition: " ",
        signDate: moment().format("DD/MM/YYYY"),
      };

      this.form = { ...this.form, ...newForm };
      this.dialog2 = true;
      this.editMode = false;
    },
    async deleteItem(item) {
      await deleteInvoice(item.id);
      alert("Invoice Deleted");
    },
    edit(item) {
      this.editMode = true;
      this.form = { ...this.form, ...item };
      this.dialog2 = true;
    },
    async save() {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return false;
      }
      this.saveLoading = true;
      if (this.editMode === false) {
        await createInvoice({
          franchiseeId: this.franData.id,
          caseId: this.caseObj.id,
          createDate: moment().format("DD/MM/YYYY hh:mm:ss"),
          invoiceNumber: this.form.invoiceNumber,
          paidAmount: this.form.paidAmount,
          paymentMethod: this.form.paymentMethod,
          OutstandingBalance: this.form.OutstandingBalance,
          RepName: this.form.RepName,
          RepPosition: this.form.RepPosition,
          signDate: this.form.signDate,
        });
        alert("Invoice Saved");
      } else {
        await editInvoice({
          id: this.form.id,
          franchiseeId: this.franData.id,
          caseId: this.caseObj.id,
          createDate: moment().format("DD/MM/YYYY hh:mm:ss"),
          invoiceNumber: this.form.invoiceNumber,
          paidAmount: this.form.paidAmount,
          paymentMethod: this.form.paymentMethod,
          OutstandingBalance: this.form.OutstandingBalance,
          RepName: this.form.RepName,
          RepPosition: this.form.RepPosition,
          signDate: this.form.signDate,
        });
        alert("Invoice Edited");
      }

      this.saveLoading = false;
      this.dialog2 = false;
    },
    print(item) {
      this.form = { ...this.form, ...item };
      var divContents = document.getElementById("printMe").innerHTML;
      var a = window.open("", "", "height=1500, width=1500");

      a.document.write("<html>");
      a.document.write("<body>");
      a.document.write("<style></style>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.print();
      document.margin = "none";
    },
    calcTotal() {
      this.gtotal = this.itemRows.reduce((accumulator, currentObject) => {
        return accumulator + parseInt(currentObject.price);
      }, 0);
    },

    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
