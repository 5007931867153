<template>
  <v-card
    :color="bgColor"
    dark
  >

    <v-card-text>
      <div class="text-sm-center">
        <img
          width='280'
          :src="`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`"
          alt="photo"
        >
      </div>
      <div class="text-sm-center mb-4 mt-3">
        <h1 class='mb-4'>
          {{helper.name}}
        </h1>
        <p>檔案編號 File Number: <span class='subtitle-1 white--text'>{{helper.portfolioId}}</span></p>
        <p>可到職日 Available on: <span class='subtitle-1 white--text'>{{helper.availabilityDate}}</span></p>
        <p>狀況 Status: <span class='subtitle-1 white--text'>{{helper.jobstatus}}</span></p>

      </div>

      <!-- <sidebar-section :options="sections.info" /> -->
      <sidebar-section :options="sections.info2" />

    </v-card-text>
  </v-card>
</template>

<script>
import SidebarSection from "./Section.vue";
export default {
  props: {
    helper: Object,
  },
  name: "Sidebar",
  components: { SidebarSection },
  mounted() {
    if (this.$route.query.color) {
      this.bgColor = "#" + this.$route.query.color;
    }
  },
  data() {
    return {
      bgColor: "#1a6978",
      sections: {
        info2: {
          title: "一般資訊",
          title2: "General Information",
          items: [
            {
              name: "會否吃豬肉? Does she eat pork?",
              icon: "mdi-pig-variant",
              text: this.helper.pork,
            },
            {
              name: "害怕動物? Afraid of pet?",
              icon: "mdi-paw",
              text: this.helper.afraidPet,
            },
            {
              name: "會否吸煙? Does she smoke?",
              icon: "mdi-smoking-off",
              text: this.helper.smoke,
            },
            {
              name: "有飲酒習慣? Does she drink alcohol?",
              icon: "mdi-liquor",
              text: this.helper.alcohol,
            },
            {
              name: "接受於平日放假?",
              name2: "Accept day-off not on Sunday?",
              icon: "mdi-calendar-alert",
              text: this.helper.acceptNoSundayOff,
            },
            {
              name: "接受與僱主家庭成員共享房間?",
              name2: "Sharing room with babies/children/elderly?",
              icon: "mdi-bunk-bed",
              text: this.helper.roomShare,
            },
            {
              name: "你是否願意和其他女傭一起工作?",
              name2: "Willing to share job with other helpers?",
              icon: "mdi-account-multiple-check",
              text: this.helper.shareJob,
            },
            {
              name: "可以接受家裏已安裝CCTV",
              name2: "Okay with CCTV",
              icon: "mdi-eye-outline",
              text: this.helper.ccTvOk,
            },
          ],
        },
      },
    };
  },
};
</script>

<style scoped>
.sidebar {
  background: #2e2e2e;
}
</style>
