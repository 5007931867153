<template>
  <div>
    <v-card>
      <v-card-title>
        Advanced Search
      </v-card-title>
      <div class="ma-5">
        <v-row>

          <v-col cols="6">
            <v-subheader>Age</v-subheader>
            <v-range-slider
              v-model="age"
              :max="65"
              :min="18"
              hide-details
              class="align-center"
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="age[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(age, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="age[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(age, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>

          </v-col>

          <v-col cols="3">
            <v-select
              label="Nationality"
              :items="['Filipino','Indonesian','No preference']"
              class="mt-8"
              v-model="nationality"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="Available From"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-menu
              v-model="menuTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="Available To"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                @input="menuTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="maritalStatusItems"
              v-model="maritalStatus"
              multiple
              label="Marital Status"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="['Oversea','Local','No preference']"
              label="Status"
              v-model="status"
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-select
              :items="religionItems"
              label="Religion"
              v-model="religion"
              required
              multiple
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-select
              :items="educationItems"
              multiple
              label="Education"
              v-model="education"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
              item-text="text"
              item-value="val"
              label="Cantonese Skill >="
              v-model="cantonese"
              required
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-select
              :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
              item-text="text"
              item-value="val"
              label="English Skill >="
              v-model="english"
              required
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-select
              :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
              item-text="text"
              item-value="val"
              label="Mandarin Skill >="
              v-model="mandarin"
              required
            ></v-select>
          </v-col>

          <v-col cols="5">
            <v-select
              :items="workCountriesItems"
              label="Countries Worked At"
              v-model="workCountries"
              multiple
            ></v-select>
          </v-col>

        </v-row>
        <Match />
      </div>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="filterItem"
        >Search</v-btn>
        <v-btn @click="clearSearch">Clear</v-btn>
      </v-card-actions>
    </v-card>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="advancedSearchResult"
      :search="search"
    >
      <template v-slot:item.id="{ item }">
        <a
          target="blank"
          @click='openLink(item.id)'
        >{{item.id}}</a>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import Match from "../components/Match.vue";
import { db } from "../firebase/firebaseConfig";
import { mapFields } from "vuex-map-fields";
export default {
  components: {
    Match,
  },
  methods: {
    openLink(id) {
      let routeData = this.$router.resolve({ name: `helpers/${id}` });
      window.open(routeData.route.name, "_blank");
    },
    clearSearch() {
      this.workCountries = [];
      this.age = [18, 65];
      this.nationality = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.english = 0;
      this.mandarin = 0;
      this.cantonese = 0;
      this.advancedSearchResult = [];
    },
    filterItem() {
      if (this.$moment(this.dateTo).isBefore(this.dateFrom)) {
        alert("please adjust your availability date");
        return false;
      }
      this.advancedSearchResult = [];
      // filter by age
      this.advancedSearchResult = this.result.filter(
        (r) => r.age < this.age[1] && r.age > this.age[0]
      );
      // filter out not valid helper
      this.advancedSearchResult = this.advancedSearchResult.filter(
        (n) => n.valid === "Yes"
      );
      // filter by status
      if (this.status && this.status !== "No preference") {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) => r.status === this.status
        );
      }
      // filter by nationality if selected
      if (this.nationality && this.nationality !== "No preference") {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) => r.nationality === this.nationality
        );
      }
      // filter by religion
      if (this.religion.length > 0) {
        let notSelectedArr = this.religionItems.filter(
          (x) => this.religion.indexOf(x) === -1
        );
        notSelectedArr.forEach(
          (n) =>
            (this.advancedSearchResult = this.advancedSearchResult.filter(
              (r) => r.religion !== n
            ))
        );
      }
      // filter by marital Status
      if (this.maritalStatus.length > 0) {
        let notSelectedArr = this.maritalStatusItems.filter(
          (x) => this.maritalStatus.indexOf(x) === -1
        );
        notSelectedArr.forEach(
          (n) =>
            (this.advancedSearchResult = this.advancedSearchResult.filter(
              (r) => r.maritalStatus !== n
            ))
        );
      }
      // filter by country worked at
      if (this.workCountries.length > 0) {
        let tempResult = [];
        this.workCountries.forEach((country) => {
          let t = this.advancedSearchResult.filter((r) =>
            r.workExp.find((rr) => rr.country === country)
          );
          tempResult = tempResult.concat(t);
        });
        this.advancedSearchResult = tempResult.reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
      }
      // filter by education
      if (this.education.length > 0) {
        let notSelectedArr = this.educationItems.filter(
          (x) => this.education.indexOf(x) === -1
        );
        notSelectedArr.forEach(
          (n) =>
            (this.advancedSearchResult = this.advancedSearchResult.filter(
              (r) => r.education !== n
            ))
        );
      }
      // filter by date
      if (this.dateFrom && this.dateTo) {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) =>
            this.$moment(r.availabilityDate).isAfter(this.dateFrom) &&
            this.$moment(r.availabilityDate).isBefore(this.dateTo)
        );
      }
      // filter by language
      if (this.english > 0) {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) => r.english >= this.english
        );
      }
      if (this.mandarin > 0) {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) => r.mandarin >= this.mandarin
        );
      }
      if (this.cantonese > 0) {
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (r) => r.cantonese >= this.cantonese
        );
      }
      if (this.frontEndMatch) {
        // filter out skills that does not match score
        this.frontEndMatch.skillset.forEach((skill) => {
          this.advancedSearchResult = this.advancedSearchResult.filter(
            (n) => n[skill] >= 3
          );
        });

        // filter out helpers base on private room
        if (this.frontEndMatch.privateRoom === "Yes") {
          this.advancedSearchResult = this.advancedSearchResult.filter(
            (n) => n.roomShare === this.frontEndMatch.privateRoom
          );
        }
        // filter out helpers base on preferred family size
        this.advancedSearchResult = this.advancedSearchResult.filter(
          (n) =>
            n.preferredfamilysize === "no preference" ||
            n.preferredfamilysize === this.frontEndMatch.familyMember
        );
      }
    },
  },
  async mounted() {
    db.collection("Helpers").onSnapshot((docs) => {
      this.result = docs.docs.map((doc) => doc.data());
    });
  },
  computed: {
    ...mapFields(["frontEndMatch"]),
  },
  data() {
    return {
      workCountriesItems: [
        "Philippines",
        "Indonesia",
        "Hong Kong",
        "Singapore",
        "Taiwan",
        "Middle East",
      ],
      workCountries: [],
      religionItems: [
        "Christian",
        "Roman Catholic",
        "Protestantism",
        "Muslim",
        "Others",
      ],
      maritalStatusItems: ["Single", "Married", "Separated", "Others"],
      educationItems: ["Junior High", "High School", "College"],
      headers: [
        { text: "Id", value: "id" },
        { text: "Valid?", value: "valid" },
        { text: "Portfolio Id", value: "portfolioId" },
        { text: "Name", value: "name" },
        { text: "Age", value: "age" },
        { text: "Date of Birth", value: "dob" },
        { text: "Type", value: "status" },
        { text: "Submission Date", value: "submitDate" },
        { text: "Availability (Date)", value: "availabilityDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      menuFrom: false,
      menuTo: false,
      advancedSearchResult: [],
      menu: null,
      result: [],
      dateFrom: null,
      dateTo: null,
      duration: null,
      country: null,
      houseHoldSize: null,

      valid: true,
      name: null,
      phone: 0,
      dob: null,
      age: [18, 65],
      nationality: null,
      education: [],
      status: null,
      maritalStatus: [],
      religion: [],
      height: 0,
      weight: 0,
      pic: null,
      videoLink: null,
      briefInfo: null,
      preferredfamilysize: 0,
      cleanliness: 0,
      discipline: 0,
      empathy: 0,
      efficiency: 0,
      friendliness: 0,
      hardworking: 0,
      honesty: 0,
      patience: 0,
      proactiveness: 0,
      responsible: 0,
      careInfant: 0,
      careChild: 0,
      careElderly: 0,
      careDisabled: 0,
      careBedridden: 0,
      carePet: 0,
      cooking: 0,
      generalHousework: 0,
      carWashing: 0,
      cantonese: 0,
      english: 0,
      mandarin: 0,
      pork: false,
      acceptNoSundayOff: false,
      roomShare: false,
      afraidPet: false,
      smoke: false,
      alcohol: false,
      illnessesSurgery: false,
    };
  },
};
</script>

<style>
</style>