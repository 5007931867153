<template>
  <v-bottom-sheet
    hide-overlay
    persistent
    v-model="sheet"
    :scrollable='true'
  >

    <v-dialog
      v-model="requestdialog"
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          Request Time for Interview
        </v-card-title>

        <v-card-text>
          Please Enter the desired time and date to interview this maid.
          <v-text-field
            v-model='interviewTimeDate'
            label='Enter time and date here'
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="requestdialog = false"
          >
            Close
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="requestInterviewTime"
          >
            Request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        Search Result
        <v-spacer>
        </v-spacer>
        <v-btn
          text
          color="red"
          @click="closeSheet"
        >
          close
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 250px;">
        <v-row>
          <v-col
            cols='2'
            :key='index'
            v-for='m,index in searchResult'
          >
            <v-card
              rounded
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Age {{m.age}}
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{m.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{m.portfolioId}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{m.status}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{m.nationality}}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  tile
                  size="80"
                  gray
                ><v-img :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${m.fileLocation}`'></v-img></v-list-item-avatar>
              </v-list-item>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn
                      small
                      @click='openLink(m.id)'
                    >
                      View
                    </v-btn>
                  </v-col>

                  <v-col>
                    <v-btn
                      small
                      color='info'
                      @click='placeHold(m)'
                    >
                      Add to Hold
                    </v-btn>
                  </v-col>

                  <v-col>
                    <v-btn
                      small
                      @click='interviewDialog(m)'
                      color='primary'
                    >

                      Interview
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-bottom-sheet>
</template>

<script>
import momentTz from "moment-timezone";
import { createNewInterview } from "../dataservices/interviewDataservices";
export default {
  mounted() {},
  props: {
    searchResult: Array,
    sheet: Boolean,
    held: Boolean,
  },
  data() {
    return {
      selectedMaidId: null,
      requestdialog: false,
      interviewTimeDate: null,
    };
  },
  methods: {
    interviewDialog(id) {
      this.requestdialog = true;
      this.selectedMaidId = id;
    },
    async requestInterviewTime() {
      await createNewInterview({
        maidPortfolioId: this.selectedMaidId.portfolioId,
        maidId: this.selectedMaidId.id,
        caseId: this.$route.params.id,
        requestTime: this.interviewTimeDate,
        status: "requested",
        createTime: momentTz().tz("Asia/Hong_Kong").format("DD/MM/YYYY"),
      });

      this.requestdialog = false;
      this.interviewTimeDate = null;
    },
    placeHold(maid) {
      this.$emit("placeHold", maid);
      this.$emit("closeSheet");
    },
    openLink(id) {
      let routeData = this.$router.resolve({ name: `/helpers/${id}` });
      window.open(routeData.route.name, "_blank");
    },
    closeSheet() {
      this.$emit("closeSheet");
    },
  },
};
</script>

<style>
</style>