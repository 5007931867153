<template>
  <v-row>
    <newMessageDialog
      v-if='dialog'
      :dialog='dialog'
      @close='dialog = false'
    />
    <v-col cols='3'>

      <v-btn
        color='primary'
        class='mb-3'
        @click='dialog = true'
      >New Message</v-btn>
      <v-card>
        <v-list subheader>

          <v-subheader>Inbox</v-subheader>
          <v-progress-linear
            v-if='loading'
            color="primary"
            indeterminate
            height="6"
          ></v-progress-linear>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="chat in messages"
              :key="chat.id"
              @click='renderConversation(chat.id)'
            >

              <v-list-item-avatar>
                <v-icon :color="chat.active ? 'deep-purple accent-4' : 'grey'">
                  mdi-message-outline
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="chat.subject"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if='role === "admin"'>
                <v-btn
                  @click='deleteMessage(chat)'
                  icon
                >
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>

            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>

    <v-col cols='9'>

      <div
        id="chat-list-container"
        class="chat-list-container"
      >
        <Converstaion
          style='padding-bottom : 180px'
          v-if='showConversation === true'
          :data='selectedConverstaion'
        />
      </div>

      <div class="chat-input-container">
        <v-container class="chat-input">
          <v-textarea
            :disabled='selectedConverstaion === null'
            v-model.trim="message"
            v-on:keyup.enter.exact="submitHandler"
            class="message"
            solo
            no-resize
            rows="2"
            name="message"
            label="Type 'Enter' to send message"
          ></v-textarea>
        </v-container>
      </div>

    </v-col>
  </v-row>
</template>

<script>
import momentTz from "moment-timezone";
import newMessageDialog from "../components/newMessageDialog.vue";
import Converstaion from "../components/converstaion";
import { db, firebase } from "../firebase/firebaseConfig";
import store from "../store/index";
import {
  getAllMessages,
  deleteMessageById,
} from "../dataservices/messagesDataservices";
import { onSnapshot } from "firebase/firestore";
export default {
  components: { Converstaion, newMessageDialog },
  async mounted() {
    this.role = store.getters.role;
    let ref = await getAllMessages();
    onSnapshot(ref, (docs) => {
      this.messages = docs.docs.map((doc) => doc.data());
      if (this.selectedConverstaion !== null) {
        this.renderConversation(this.selectedConverstaion.id);
      }
      this.loading = false;
    });
  },
  methods: {
    async deleteMessage(chat) {
      deleteMessageById(chat.id);
    },
    async submitHandler() {
      const role = store.getters.role;
      const claims = store.getters.claims;
      if (role === "admin") {
        await db
          .collection("Messages")
          .doc(this.selectedConverstaion.id)
          .update({
            conversation: firebase.firestore.FieldValue.arrayUnion({
              from: this.selectedConverstaion.from,
              message: this.message,
              time: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY HH:mm:ss"),
            }),
          });
      } else {
        await db
          .collection("Messages")
          .doc(this.selectedConverstaion.id)
          .update({
            conversation: firebase.firestore.FieldValue.arrayUnion({
              from: claims.franchiseeId,
              message: this.message,
              time: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY HH:mm:ss"),
            }),
          });
      }
      this.message = undefined;
    },
    renderConversation(id) {
      this.selectedConverstaion = this.messages.find((m) => m.id === id);
      this.showConversation = true;
    },
  },
  data() {
    return {
      role: null,
      loading: true,
      selectedItem: null,
      dialog: false,
      message: "",
      selectedConverstaion: null,
      showConversation: false,
      messages: [],
    };
  },
};
</script>

<style scoped>
.chat-input-container {
  background-color: #eeeeee;
  width: 60%;
  position: fixed;
  bottom: 0;
}
</style>