<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-card>
      <v-card-title>
        On Hold Maids
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading='loading'
        loading-text="Loading data... Please wait"
        :headers="headers"
        :items="result"
        :search="search"
      >
        <template v-slot:item.heldMaid="{ item }">
          <a @click='()=> {$router.push(`/helpers/${item.heldMaid}`)}'>{{item.heldMaid}}</a>
        </template>
        <template v-slot:item.heldsince="{ item }">
          <span>{{formattimesince(item.heldMaidTime)}}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <unHoldMaidBtn
            @onComplete='()=>{}'
            :disabled='item.heldMaid === null'
            :caseId='item.id'
            :heldMaid='item.heldMaid'
          />
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>


<script>
import momentTz from "moment-timezone";
import unHoldMaidBtn from "../components/unHoldMaidBtn.vue";
import { onSnapshot } from "firebase/firestore";
import { getAllonHoldHelpers } from "../dataservices/caseDataservices";
export default {
  components: {
    unHoldMaidBtn,
  },
  data: () => ({
    dialog2: false,
    loading: true,
    dialog: false,
    search: null,
    result: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Maid Held", value: "heldMaid" },
      { text: "Maid Held Time", value: "heldMaidTime" },
      { text: "Held Since", value: "heldsince" },
      { text: "Action", value: "action" },
    ],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "On Hold Maids",
        disabled: true,
        href: "",
      },
    ],
  }),
  methods: {
    formattimesince(heldMaidTime) {
      var time = momentTz(heldMaidTime, "DD-MM-YYYY HH:mm:ss").add(
        "-15",
        "hours"
      );
      return momentTz(time, "YYYYMMDD").fromNow();
    },
    exportAll() {
      // Create link and download
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:application/json;charset=utf-8,%EF%BB%BF" +
          encodeURIComponent(JSON.stringify(this.result))
      );
      link.setAttribute("download", "helper");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  async mounted() {
    const query = await getAllonHoldHelpers();
    onSnapshot(query, (docs) => {
      this.result = docs.docs.map((doc) => doc.data());
      this.loading = false;
    });
  },
};
</script>
