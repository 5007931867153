import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Helpers from "../views/Helpers.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import MatchResults from "../views/MatchResults.vue";
import MatchResult from "../views/MatchResult.vue";
import Helper from "../views/Helper.vue";
import MatchedResults from "../views/MatchedResults.vue";
import MatchedResult from "../views/MatchedResult.vue";
import Noresults from "../views/Noresults.vue";
import Noresult from "../views/Noresult.vue";
import NewHelper from "../views/NewHelper.vue";
import Register from "../views/public/Register.vue";
import Applications from "../views/Applications.vue";
import Application from "../views/Application.vue";
import Fetch from "../views/public/Fetch.vue";
import store from "../store/index";
import adminTools from "../views/AdminTools";
import advancedSearch from "../views/AdvancedSearch";
import cases from "../views/Cases";
import Case from "../views/Case";
import newcase from "../views/NewCase";
import onHoldMaid from "../views/OnHoldMaid";
import manageFranchisees from "../views/manageFranchisees";
import manageInterviews from "../views/manageInterviews";
import manageInterview from "../views/manageInterview";
import messages from "../views/messages";
import invoiceTemplate from "../views/invoiceTemplate";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { requiresAuth: true },
	},
	{
		path: "/helper/matchedresults",
		name: "Matched Results",
		component: MatchedResults,
		meta: { requiresAuth: true },
	},
	{
		path: "/helper/matchedresult/:id",
		name: "Matched Result",
		component: MatchedResult,
		meta: { requiresAuth: true },
	},
	{
		path: "/noResultfollowup",
		name: "No Result Follow up",
		component: Noresults,
		meta: { requiresAuth: true },
	},
	{
		path: "/noResultfollowup/:id",
		name: "No Result Follow up case",
		component: Noresult,
		meta: { requiresAuth: true },
	},
	{
		path: "/matchresults",
		name: "MatchResults",
		component: MatchResults,
		meta: { requiresAuth: true },
	},
	{
		path: "/matchresult/:id",
		name: "MatchResult",
		component: MatchResult,
		meta: { requiresAuth: true },
	},
	{
		path: "/helper/all",
		name: "helper",
		component: Helpers,
		meta: { requiresAuth: true },
	},
	{
		path: "/helper/all?type=myHelpers",
		name: "helper",
		component: Helpers,
		meta: { requiresAuth: true },
	},
	{
		path: "/helpers/:id",
		component: Helper,
		meta: { requiresAuth: true },
	},
	{
		path: "/newHelper",
		name: "New Domestic helper",
		component: NewHelper,
		meta: { requiresAuth: true },
	},
	{
		path: "/helper/applications",
		name: "New Domestic helper Applications",
		component: Applications,
		meta: { requiresAuth: true },
	},
	{
		path: "/application/:id",
		name: "New Domestic helper Application",
		component: Application,
		meta: { requiresAuth: true },
	},
	{
		path: "/public/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/fetch/:id",
		name: "Fetch",
		component: Fetch,
	},
	{
		path: "/admin/adminTools",
		name: "admin tools",
		component: adminTools,
		meta: { requiresAuth: true, requiresAdmin: true },
	},
	{
		path: "/admin/franchisees",
		name: "manage franchisee",
		component: manageFranchisees,
		meta: { requiresAuth: true, requiresAdmin: true },
	},
	{
		path: "/admin/interviews",
		name: "manage interview requests",
		component: manageInterviews,
		meta: { requiresAuth: true, requiresAdmin: true },
	},
	{
		path: "/admin/interview/:id",
		name: "manage interview",
		component: manageInterview,
		meta: { requiresAuth: true, requiresAdmin: true },
	},
	{
		path: "/advancedSearch",
		name: "advanced Search",
		component: advancedSearch,
		meta: { requiresAuth: true },
	},
	{
		path: "/case/:id",
		name: "Case",
		component: Case,
		meta: { requiresAuth: true },
	},
	{
		path: "/invoiceTemplate",
		name: "Invoice Template",
		component: invoiceTemplate,
		meta: { requiresAuth: true },
	},
	{
		path: "/cases/all",
		name: "Cases",
		component: cases,
		meta: { requiresAuth: true },
	},
	{
		path: "/cases/new",
		name: "New Cases",
		component: newcase,
		meta: { requiresAuth: true },
	},
	{
		path: "/cases/onHold",
		name: "Maids on Hold",
		component: onHoldMaid,
		meta: { requiresAuth: true },
	},
	{
		path: "/inbox",
		name: "Inbox",
		component: messages,
		meta: { requiresAuth: true, useContainer: true },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.fullPath.includes("register")) {
		document.title = "Maid Expert Register Page";
	} else {
		document.title = "Maid Expert Back Office";
	}

	if (to.fullPath.includes("cases")) {
		store.commit("set_item", "Cases");
	} else if (to.fullPath.includes("admin")) {
		store.commit("set_item", "Admin");
	} else if (to.fullPath.includes("helper")) {
		store.commit("set_item", "Helpers");
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				let { claims } = await firebase
					.auth()
					.currentUser.getIdTokenResult(true);

				store.commit("set_claims", claims);
				store.commit("set_role", claims.role);
				store.dispatch("filterAdminItem", claims.role);
				store.dispatch("fetchUser", user);

				if (to.meta.requiresAdmin && store.getters.claims.role !== "admin") {
					next({
						path: "/",
					});
				} else {
					next();
				}
			} else {
				next({
					path: "/login",
				});
			}
		});
	} else {
		next();
	}
	// firebase.auth().onAuthStateChanged(async (user) => {
	// 	if (user) {
	// 		let { claims } = await firebase.auth().currentUser.getIdTokenResult(true);
	// 		store.commit("set_claims", claims);
	// 		store.commit("set_role", claims.role);
	// 		store.dispatch("fetchUser", user);
	// 		if (to.meta.requiresAdmin && store.getters.claims.role !== "admin") {
	// 			next({
	// 				path: "/",
	// 			});
	// 		} else {
	// 			next();
	// 		}
	// 	} else {
	// 		next({
	// 			path: "/login",
	// 		});
	// 	}
	// });
});

export default router;
