import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";

Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		claims: null,
		role: null,
		items: [
			{
				title: "Home",
				icon: "mdi-home",
				to: "/",
				active: false,
				requireAdmin: false,
			},
			{
				title: "Inbox",
				icon: "mdi-chat",
				to: "/inbox",
				active: false,
				requireAdmin: false,
			},
			{
				icon: "mdi-clipboard-account-outline",
				title: "Cases",
				active: false,
				requireAdmin: false,
				items: [
					{ title: "All Cases", to: "/cases/all", requireAdmin: false },
					{ title: "New Case", to: "/cases/new", requireAdmin: false },
					{ title: "Maids On Hold", to: "/cases/onHold", requireAdmin: false },
				],
			},
			{
				icon: "mdi-account-group",
				active: false,
				requireAdmin: false,
				items: [
					{
						title: "Domestic Helpers",
						icon: "mdi-account-group",
						to: "/helper/all",
						requireAdmin: false,
					},
					{
						title: "Advanced Searching",
						icon: "mdi-account-search",
						to: "/advancedSearch",
						requireAdmin: false,
					},
					{
						title: "New Domestic Helper",
						icon: "mdi-account-group",
						to: "/newhelper",
						requireAdmin: false,
					},
					{
						title: "Helpers Applications",
						icon: "mdi-application-edit-outline",
						to: "/helper/applications",
						requireAdmin: false,
					},
				],
				title: "Helpers",
			},
			{
				icon: "mdi-account-eye",
				active: false,
				title: "Admin",
				requireAdmin: true,
				items: [
					{
						title: "Admin Tools",
						icon: "mdi-account-eye",
						to: "/admin/adminTools",
						requireAdmin: true,
					},
					{
						title: "Manage Franchisee",
						icon: "mdi-account-eye",
						to: "/admin/franchisees",
						requireAdmin: true,
					},
					{
						title: "Interview Requests",
						icon: "mdi-video-account",
						to: "/admin/interviews",
						requireAdmin: true,
					},
					{
						title: "Match Results",
						icon: "mdi-book-information-variant",
						to: "/matchresults",
						requireAdmin: true,
					},
					{
						title: "Matched Results",
						icon: "mdi-account-check",
						to: "/helper/matchedresults",
						requireAdmin: true,
					},
					{
						title: "No Match Results",
						icon: "mdi-account-question",
						to: "/noResultfollowup",
						requireAdmin: true,
					},
				],
			},
		],
		frontEndMatch: null,
		user: {
			loggedIn: false,
			data: null,
		},
	},
	getters: {
		getField,
		role(state) {
			return state.role;
		},
		claims(state) {
			return state.claims;
		},
		user(state) {
			return state.user;
		},
	},
	mutations: {
		updateField,
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value;
		},
		set_role(state, data) {
			state.role = data;
		},
		set_claims(state, data) {
			state.claims = data;
		},
		SET_USER(state, data) {
			state.user.data = data;
		},
		set_admin_item(state, data) {
			if (data !== "admin") {
				state.items = state.items.filter((item) => item.requireAdmin === false);
			}
		},
		set_item(state, data) {
			let target = state.items.find((i) => i.title === data);
			if (target) {
				target.active = true;
			} else {
				state.items.forEach((item) => (item.active = false));
			}
		},
	},
	actions: {
		async filterAdminItem({ commit }, role) {
			commit("set_admin_item", role);
		},
		async fetchUser({ commit }, user) {
			commit("SET_LOGGED_IN", user !== null);
			if (user) {
				commit("SET_USER", {
					displayName: user.displayName,
					email: user.email,
				});
			} else {
				commit("SET_USER", null);
			}
		},
	},
});
