<template>
  <v-container>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this Franchisees?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <v-card>

      <interviewTable
        :headers='headers'
        :interviewData=interviewData
      />
    </v-card>
  </v-container>
</template>

<script>
import { getAllInterviews } from "../dataservices/interviewDataservices";

import interviewTable from "../components/interviewTable.vue";
export default {
  components: {
    interviewTable,
  },
  async mounted() {
    let query = await getAllInterviews();
    query.onSnapshot((docs) => {
      this.interviewData = docs.docs.map((doc) => doc.data());
    });
  },
  methods: {
    async Handlechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.selectedItem.base64 = image;
        this.selectedItem.base64 = this.selectedItem.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;
      };
      reader.readAsDataURL(file);
    },
    upload() {
      document.getElementById("fileid").click();
    },

    async confirmDelete() {
      this.dialog2 = false;

      this.selectedItem = null;
      alert("Franchisee Deleted");
    },
    async deleteItem(item) {
      this.selectedItem = item.id;
      this.dialog2 = true;
    },
    async editItem(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
  },
  data() {
    return {
      fileName: null,
      fileType: null,
      uploading: false,
      dialog: false,
      selectedItem: null,
      dialog2: false,
      loading: true,
      headers: [
        { text: "ID", value: "id" },
        { text: "Create Time", value: "createTime" },
        { text: "Franchisee", value: "franchiseeCode" },
        { text: "Case #", value: "caseId" },
        { text: "Request Time", value: "requestTime" },
        { text: "Maid Portfolio Id", value: "maidPortfolioId" },
        { text: "Status", value: "status" },
        { text: "Meeting", value: "meetinglink" },
      ],
      interviewData: [],
      search: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Manage Franchisees",
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>

<style>
</style>