<template>
  <v-app
    id="dark-template"
    v-if='helper'
  >

    <v-content>
      <v-container fluid>
        <v-layout
          align-center
          justify-center
        >
          <v-flex fillheight>
            <v-layout>
              <v-flex
                md4
                sm4
                col4
              >
                <sidebar-container
                  :helper='helper'
                  style='height : 100%'
                />
              </v-flex>
              <v-flex
                md8
                sm8
                col8
              >
                <content-container
                  :helper='helper'
                  class="fill-height"
                />
              </v-flex>
            </v-layout>
            <v-layout>

            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import SidebarContainer from "./sidebar/Container.vue";
import ContentContainer from "./content/Container";
import axios from "axios";
export default {
  components: {
    ContentContainer,
    SidebarContainer,
  },
  data() {
    return {
      helper: null,
    };
  },
  async mounted() {
    let options = {
      "content-type": "text/json",
      "Access-Control-Allow-Origin": "*",
    };
    this.helper = await axios.post(
      "https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/getHelper",
      {
        id: this.$route.params.id,
      },
      options
    );
    this.helper = this.helper.data;
  },
};
</script>

<style scoped>
</style>