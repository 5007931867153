<template>
  <div>

    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-form>
      <v-text-field
        v-model="email"
        :value='email'
        label="E-mail"
        readonly
      ></v-text-field>

      <v-text-field
        v-model="phone"
        label="Phone"
        readonly
      ></v-text-field>

      <v-text-field
        v-model="prefix"
        label="Prefix"
        readonly
      ></v-text-field>
      <v-text-field
        v-model="lastName"
        label="Last Name"
        readonly
      ></v-text-field>
      <span>Customer Selected Strength:</span><v-chip
        :key='index'
        v-for='p,index in selectedPersonality'
        class="ma-2"
      >
        {{p}}
      </v-chip>
      <p><span>Customer Selected Skills:</span><v-chip
          :key='index'
          v-for='s,index in selectedSkillset'
          class="ma-2"
        >
          {{s}}
        </v-chip></p>
      <p>System returned result id: <a @click='()=> {$router.push(`/matchresult/${resultID}`)}'>{{resultID}}</a></p>
      <p>Language User Selected: {{language}}</p>

      <v-btn
        color="primary"
        class="mr-4"
        @click="review"
      >
        Reviewed
      </v-btn>

    </v-form>

  </div>
</template>

<script>
import { db } from "../firebase/firebaseConfig";

export default {
  methods: {
    review() {
      db.collection("MatchResultsWithContactInfo")
        .doc(this.$route.params.id)
        .update({
          status: "reviewed",
        });
    },
  },
  mounted() {
    db.collection("MatchResultsWithContactInfo")
      .where("id", "==", this.$route.params.id)
      .onSnapshot((docs) => {
        this.result = docs.docs.map((doc) => doc.data())[0];
        this.email = this.result.email;
        this.phone = this.result.phone;
        this.lastName = this.result.lastName;
        this.prefix = this.result.prefix;
        this.selectedPersonality = this.result.selectedPersonality;
        this.selectedSkillset = this.result.selectedSkillset;
        this.resultID = this.result.matchResultID;
        this.language = this.result.language;
      });
  },
  data() {
    return {
      language: "",
      prefix: "",
      lastName: "",
      email: "",
      phone: "",
      resultID: "",
      selectedPersonality: [],
      selectedSkillset: [],
      result: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Matched Results",
          disabled: false,
          href: "/helper/matchedresults",
        },
        {
          text: `Matched Result: ${this.$route.params.id}`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>