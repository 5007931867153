<template>
  <v-card
    v-if="true"
    flat
  >
    <v-card-title>
      <h3
        :style="{color: color }"
        class="font-weight-bold mb-1"
      >
        {{ title }}
        <p>{{ title2 }}</p>
      </h3>
      <v-spacer />
      <slot name="actions" />
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
  <div
    v-else
    class="mb-5"
  >
    <div class="title mb-3">
      <h2>
        {{ title }}
      </h2>
      <template v-if="$slots.actions">
        <v-spacer />
        <slot name="actions" />
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$route.query.color) {
      this.color = "#" + this.$route.query.color;
    }
  },
  data() {
    return {
      color: "#e75d24",
    };
  },
  name: "ContentSection",
  components: {},
  props: {
    title: { type: String, default: "" },
    title2: { type: String, default: "" },
  },
};
</script>

<style scoped>
.title {
  border-bottom: 2px #bfbfbf solid;
  line-height: 1.5 !important;
}
</style>
