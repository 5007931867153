export const downloadOnlyFile = [
	{
		fileName: "EXTEND 1 MONTH LETTER (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/EXTEND%201%20MONTH%20LETTER%20(NEW).pdf",
	},
	{
		fileName: "Financial supporting letter.PDF",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Financial%20supporting%20letter.PDF",
	},
	{
		fileName: "Job-Offer (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Job-Offer%20(NEW).pdf",
	},
	{
		fileName: "Mutual-Agreement (NEW ENG, INDO).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Mutual-Agreement%20(NEW%20ENG%2C%20INDO).pdf",
	},
	{
		fileName: "One-Month-Notice (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/One-Month-Notice%20(NEW).pdf",
	},
	{
		fileName: "POLO cert 2022 (1).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/POLO%20cert%202022%20(1).pdf",
	},
	{
		fileName: "Receipt for Payments upon Termination of Contract rev (Eng).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Receipt%20for%20Payments%20upon%20Termination%20of%20Contract%20rev%20(Eng).pdf",
	},
	{
		fileName: "Release-Letter (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Release-Letter%20(NEW).pdf",
	},
	{
		fileName: "SPEED UP (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/SPEED%20UP%20(NEW).pdf",
	},
	{
		fileName: "Supporting-Letter-Holiday (NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Supporting-Letter-Holiday%20(NEW).pdf",
	},
	{
		fileName: "Supporting-Letter-Overstay(NEW).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/Supporting-Letter-Overstay(NEW).pdf",
	},
	{
		fileName: "id407e (1).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/id407e%20(1).pdf",
	},
	{
		fileName: "id407g-change address.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/id407g-change%20address.pdf",
	},
	{
		fileName: "id934a.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/id934a.pdf",
	},
	{
		fileName: "letter of guarantee (2) (1).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/letter%20of%20guarantee%20(2)%20(1).pdf",
	},
	{
		fileName: "外傭僱主簽收確認記錄.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E5%A4%96%E5%82%AD%E5%83%B1%E4%B8%BB%E7%B0%BD%E6%94%B6%E7%A2%BA%E8%AA%8D%E8%A8%98%E9%8C%84.pdf",
	},
	{
		fileName: "外傭簽收及確認紀錄appendix5a_ind_chi (edited).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E5%A4%96%E5%82%AD%E7%B0%BD%E6%94%B6%E5%8F%8A%E7%A2%BA%E8%AA%8D%E7%B4%80%E9%8C%84appendix5a_ind_chi%20(edited).pdf",
	},
	{
		fileName: "外傭簽收及確認紀錄appendix5a_taga_chi (edited).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E5%A4%96%E5%82%AD%E7%B0%BD%E6%94%B6%E5%8F%8A%E7%A2%BA%E8%AA%8D%E7%B4%80%E9%8C%84appendix5a_taga_chi%20(edited).pdf",
	},
	{
		fileName: "外傭簽收確認紀錄 THAI.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E5%A4%96%E5%82%AD%E7%B0%BD%E6%94%B6%E7%A2%BA%E8%AA%8D%E7%B4%80%E9%8C%84%20THAI.pdf",
	},
	{
		fileName: "多聘一名女傭信 (additional helper).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E5%A4%9A%E8%81%98%E4%B8%80%E5%90%8D%E5%A5%B3%E5%82%AD%E4%BF%A1%20(additional%20helper).pdf",
	},
	{
		fileName: "服務協議 THAI.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E6%9C%8D%E5%8B%99%E5%8D%94%E8%AD%B0%20THAI.pdf",
	},
	{
		fileName: "與外傭簽訂的服務協議樣本appendix2a_ind (edited).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E8%88%87%E5%A4%96%E5%82%AD%E7%B0%BD%E8%A8%82%E7%9A%84%E6%9C%8D%E5%8B%99%E5%8D%94%E8%AD%B0%E6%A8%A3%E6%9C%ACappendix2a_ind%20(edited).pdf",
	},
	{
		fileName: "與外傭簽訂的服務協議樣本appendix2a_taga (edited).pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/%E8%88%87%E5%A4%96%E5%82%AD%E7%B0%BD%E8%A8%82%E7%9A%84%E6%9C%8D%E5%8B%99%E5%8D%94%E8%AD%B0%E6%A8%A3%E6%9C%ACappendix2a_taga%20(edited).pdf",
	},
	{
		fileName: "Id988a.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/id988a.pdf",
		
	},
	{
		fileName: "Id988b.pdf",
		url: "https://storage.googleapis.com/starjasmine-aacba.appspot.com/ID988B.pdf",
		
	}
];
