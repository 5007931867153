<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading helper data.....
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <HelperForm
      v-if='result'
      :dataFromDB='result'
      mode='update'
    />

  </div>
</template>

<script>
import HelperForm from "../components/HelperForm.vue";
import { getHelper } from "../dataservices/helperDataservies";
import { onSnapshot } from "firebase/firestore";
export default {
  components: {
    HelperForm,
  },
  async mounted() {
    this.dialog = true;
    let query = getHelper(this.$route.params.id);

    onSnapshot(query, (docs) => {
      this.result = docs.docs.map((doc) => doc.data())[0];
      this.dialog = false;
    });
  },
  data() {
    return {
      dialog: false,
      fileURL: null,
      result: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Domestic Helpers",
          disabled: false,
          href: "/helper/all",
        },
        {
          text: `Helper ${this.$route.params.id}`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>