<template>
  <v-app>
    <v-container>

      <v-layout justify-center>
        <v-flex
          mb-10
          xs12
          sm6
          md6
        >
          <div class="text-xs-center mb-10">
            <v-img
              class="ma-3"
              contain
              height='350'
              position="center"
              src="../assets/blacktextlogo.5e70d623.png"
            ></v-img>
          </div>
          <v-card class="elevation-12 mt-3">

            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <h3
                  v-if='loginError'
                  class='ma-5'
                  style='color : red'
                >Please check your user name or password and try again.</h3>
                <v-text-field
                  prepend-icon="person"
                  name="email"
                  label="Email"
                  type="email"
                  v-model="email"
                  :rules="emailRules"
                  required
                >
                </v-text-field>
                <v-text-field
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  id="password"
                  type="password"
                  required
                  v-model="password"
                  :rules="passwordRules"
                >
                </v-text-field>
                <v-btn
                  block
                  :loading='loading'
                  color="primary"
                  :disabled="!valid"
                  @click="submit"
                >Login</v-btn>

              </v-form>
            </v-card-text>

          </v-card>
        </v-flex>
      </v-layout>

    </v-container>

  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export default {
  mounted() {},
  data() {
    return {
      loading: false,
      loginError: false,
      valid: false,
      error: null,
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 6 || "Password must be greater than 6 characters",
      ],
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.loginError = false;
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push("/");
          })
          .catch(() => {
            this.loginError = true;
            this.loading = false;
          });
      }
    },

    // submit() {
    //   const provider = new firebase.auth.GoogleAuthProvider();
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(() => {
    //       this.$router.push("/");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.ui.button {
  &.google-auth__button {
    padding: 12px 16px;
    background: #ffffff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.22px;
    color: rgba(0, 0, 0, 0.54);
    &:hover {
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    }
    .google-auth__logo {
      display: inline-block;
      margin-right: 6px;
      height: 24px;
      width: 24px;
      line-height: 24px;
      vertical-align: top;
    }
    &.disabled,
    &:disabled {
      background: #eeeeee;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    }
  }
}

html,
body,
.stage {
  height: 100%;
}
.stage {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>