<template>
  <v-card>
    <v-card-title>
      {{data.subject}}
    </v-card-title>
    <v-list two-line>

      <template>

        <v-list-item
          :key="item.id"
          v-for="(item) in data.conversation"
        >

          <v-list-item-avatar>
            <v-avatar
              :color="findName(item.from) === 'Admin' ? 'teal' : 'orange' "
              size="48"
            >
              <span class="white--text text-h5">{{findName(item.from,true)}}</span>
            </v-avatar>

          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="findName(item.from)"></v-list-item-title>
            <v-list-item-subtitle v-text="item.message"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>

            <v-list-item-subtitle v-text="formatTime(item.time)"></v-list-item-subtitle>

          </v-list-item-action>
        </v-list-item>

      </template>

    </v-list>
  </v-card>
</template>

<script>
export default {
  mounted() {},
  methods: {
    formatTime(inputTime) {
      return inputTime;
    },
    findName(id, firstLetterOnly = false) {
      let name;
      if (this.data.fromId === id) {
        name = this.data.from;
      } else {
        name = this.data.to;
      }
      if (firstLetterOnly) {
        return name.charAt(0);
      } else {
        return name;
      }
    },
  },
  props: {
    data: Object,
  },
};
</script>

<style>
</style>