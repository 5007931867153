<template>
  <div>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this case?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <v-container>

      <v-btn
        color="primary"
        to='/cases/new'
        class='mb-5'
      >+ New Case</v-btn>
      <h2>All Cases</h2>
      <v-card>
        <v-card-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :loading='loading'
          loading-text="Loading data... Please wait"
          :headers="headers"
          :items="result"
          :search="search"
          multi-sort
        >

          <template v-slot:item.bio.franchiseeId="{ item }">
            {{formatFranchiseeID(item.bio.franchiseeId)}}
          </template>

          <template v-slot:item.bio.agentId="{ item }">
            {{formatAgentID(item.bio.agentId)}}
          </template>

          <template v-slot:item.caseStatus="{ item }">
            <v-chip
              dark
              :color="getColor(item.caseStatus)"
            >
              {{ item.caseStatus }}
            </v-chip>
          </template>
          <template v-slot:item.caseCreatedAt="{ item }">
            <span>{{formatCreatedAt(item.caseCreatedAt)}}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.caseNumber="{ item }">
            <a @click='openLink(item.caseNumber)'>{{item.caseNumber}}</a>
          </template>

          <template v-slot:item.requests[0].adjustedRequest.age="{ item }">
            <span>{{item.requests[0].adjustedRequest.age[0]}} to {{item.requests[0].adjustedRequest.age[1]}}</span>
          </template>

        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { db } from "../firebase/firebaseConfig";
import { mapFields } from "vuex-map-fields";
import { getAllCases } from "../dataservices/caseDataservices";
import { getAllFranchisees } from "../dataservices/adminDataservices";
export default {
  computed: {
    ...mapFields(["claims", "role"]),
  },
  async mounted() {
    let query = await getAllCases();

    this.Franchisees = await getAllFranchisees();
    query.onSnapshot((docs) => {
      this.result = docs.docs.map((doc) => doc.data());
      this.loading = false;
    });
  },
  methods: {
    async confirmDelete() {
      this.dialog2 = false;
      await db.collection("cases").doc(this.selectedDeleteItem).delete();
      this.selectedDeleteItem = null;
      alert("Case Deleted");
    },
    async deleteItem(item) {
      this.selectedDeleteItem = item.id;
      this.dialog2 = true;
    },
    formatFranchiseeID(item) {
      let data = this.Franchisees.find((f) => f.id === item);
      return data ? data.franchiseeCode : "";
    },
    formatAgentID(item) {
      let data = this.AgentList.find((a) => a.id === item);
      return data ? data.agentCode : "";
    },
    formatCreatedAt(time) {
      return time;
    },
    getColor(caseStatus) {
      switch (caseStatus) {
        case "Looking for Helper":
          return "red";
        case "Completed":
          return "green";
        case "Processing":
          return "orange";
        case "No need Anymore":
          return "grey";
      }
    },
    openLink(id) {
      let routeData = this.$router.resolve({ name: `/case/${id}` });
      window.open(routeData.route.name, "_self");
    },
  },
  data() {
    return {
      dialog2: false,
      loading: true,
      search: null,
      result: [],
      AgentList: [],
      Franchisees: [],
      headers: [
        { text: "Case #", value: "caseNumber" },
        { text: "Status", value: "caseStatus" },
        { text: "Created At", value: "caseCreatedAt" },
        { text: "Franchise Code", value: "bio.franchiseeId" },
        { text: "Agent Code", value: "bio.agentId" },
        { text: "Customer Id", value: "bio.customerid" },
        { text: "helper profile Id", value: "heldMaid" },
        { text: "Customer First Name", value: "bio.customerFirstName" },
        { text: "Customer Last Name", value: "bio.customerLastName" },
        { text: "Request Status", value: "requests[0].adjustedRequest.status" },
        {
          text: "Request Nationality",
          value: "requests[0].adjustedRequest.nationality",
        },
        { text: "Request Age", value: "requests[0].adjustedRequest.age" },
        { text: "Action", value: "action" },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "All Cases",
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>

<style>
</style>