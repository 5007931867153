<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <HelperForm mode='new' />

  </div>
</template>


<script>
import HelperForm from "../components/HelperForm.vue";

export default {
  components: {
    HelperForm,
  },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Domestic Helpers",
        disabled: false,
        href: "/helper/all",
      },
      {
        text: "New Domestic Helper",
        disabled: true,
        href: "",
      },
    ],
  }),
};
</script>
