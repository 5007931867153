<template>
  <v-form v-if='editFormObj.id407'>
    <v-text-field
      label='Maid Address'
      v-model='editFormObj.id407.maidAddress'
    >
    </v-text-field>

    <v-text-field
      label='Wage per Month'
      v-model='editFormObj.id407.wage'
    >
    </v-text-field>
    <v-text-field
      label='Allowance per Month'
      v-model='editFormObj.id407.allowance'
    >
    </v-text-field>
    <v-text-field
      label='Withness 1'
      v-model='editFormObj.id407.withness1'
    >
    </v-text-field>
    <v-text-field
      label='Withness 2'
      v-model='editFormObj.id407.withness2'
    >
    </v-text-field>
    <v-text-field
      label='Home Size (sqft)'
      v-model='editFormObj.id407.housesize'
    >
    </v-text-field>

    <v-text-field
      label='Page 4 Others (please specify) line 1'
      v-model='editFormObj.id407.page4Other1'
    >
    </v-text-field>

    <v-text-field
      label='Page 4 Others (please specify) line 2'
      v-model='editFormObj.id407.page4Other2'
    >
    </v-text-field>

    <v-text-field
      label='Number of Adult (Page 3 ,2B)'
      v-model='editFormObj.id407.adultNum'
    >
    </v-text-field>

    <v-text-field
      label='Number of Minor (Page 3 ,2B)'
      v-model='editFormObj.id407.MinorNum'
    >
    </v-text-field>

    <v-text-field
      label='Number of Minor (age5-18) (Page 3 ,2B)'
      v-model='editFormObj.id407.minor518'
    >
    </v-text-field>

    <v-text-field
      label='Number of Expecting babies (Page 3 ,2B)'
      v-model='editFormObj.id407.babies'
    >
    </v-text-field>
    <v-row align="center">
      <v-checkbox
        @change='changePage3CheckBox(2)'
        v-model="editFormObj.id407.page3Yes"
        hide-details
        label='Yes for page 3, 3A'
        class="shrink mr-2 mt-0"
      ></v-checkbox>
      <v-text-field
        :disabled="!editFormObj.id407.page3Yes"
        v-model='editFormObj.id407.roomSize'
        label="Estimated Size of the servant room (SQFT)"
      ></v-text-field>
    </v-row>

    <v-row align="center">
      <v-checkbox
        @change='changePage3CheckBox(1)'
        v-model="editFormObj.id407.page3No"
        hide-details
        class="shrink mr-2 mt-0"
        label='No for page 3, 3A'
      ></v-checkbox>

    </v-row>

    <div class='ma-5'>

      <v-row align="center">
        <v-checkbox
          @change='changePage3BCheckBox(1)'
          :disabled='!editFormObj.id407.page3No'
          v-model="editFormObj.id407.page3No1"
          hide-details
          class="shrink mr-2 mt-0"
          label='Share a room with child/childen'
        ></v-checkbox>
        <v-text-field
          v-model='editFormObj.id407.page3No1ChildNum'
          :disabled='!editFormObj.id407.page3No1'
          label="# of child"
        ></v-text-field>
        <v-text-field
          v-model='editFormObj.id407.page3No1ChildAge'
          :disabled='!editFormObj.id407.page3No1'
          label="Age of child"
        ></v-text-field>
      </v-row>
      <v-row align="center">
        <v-checkbox
          @change='changePage3BCheckBox(2)'
          :disabled='!editFormObj.id407.page3No'
          v-model="editFormObj.id407.page3No2"
          hide-details
          class="shrink mr-2 mt-0"
          label='separate partition area'
        ></v-checkbox>
        <v-text-field
          :disabled='!editFormObj.id407.page3No2'
          v-model='editFormObj.id407.page3No2SQFT'
          label="Area in SQFT"
        ></v-text-field>
      </v-row>
      <v-row align="center">
        <v-checkbox
          @change='changePage3BCheckBox(3)'
          :disabled='!editFormObj.id407.page3No'
          v-model="editFormObj.id407.page3No3"
          hide-details
          class="shrink mr-2 mt-0"
          label='others'
        ></v-checkbox>
        <v-text-field
          v-model='editFormObj.id407.page3No3DetailLine1'
          :disabled='!editFormObj.id407.page3No3'
          label="Others Line 1"
        ></v-text-field>
        <v-col cols='12'>
          <v-text-field
            v-model='editFormObj.id407.page3No3DetailLine2'
            class='ml-5'
            :disabled='!editFormObj.id407.page3No3'
            label="Others Line 2"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-btn
      :loading='saveLoading'
      @click='save'
    >
      Save
    </v-btn>
  </v-form>
</template>

<script>
import { db } from "../../firebase/firebaseConfig";
export default {
  props: {
    caseId: String,
    caseData: Object,
    maidData: Object,
  },
  mounted() {
    this.editFormObj = this.caseData;
    if (!this.editFormObj.id407) {
      this.editFormObj.id407 = this.id407;
    }
  },
  methods: {
    changePage3BCheckBox(box) {
      if (box === 1) {
        this.editFormObj.id407.page3No2 = false;

        this.editFormObj.id407.page3No3 = false;
      }
      if (box === 2) {
        this.editFormObj.id407.page3No1 = false;

        this.editFormObj.id407.page3No3 = false;
      }
      if (box === 3) {
        this.editFormObj.id407.page3No1 = false;

        this.editFormObj.id407.page3No2 = false;
      }
    },
    changePage3CheckBox(box) {
      if (box === 1) {
        this.editFormObj.id407.page3Yes = false;
      } else {
        this.editFormObj.id407.page3No = false;
      }
    },
    async save() {
      this.saveLoading = true;
      await db.collection("cases").doc(this.caseData.id).update(
        {
          id407: this.editFormObj.id407,
        },
        { merge: true }
      );
      this.saveLoading = false;
    },
  },
  data() {
    return {
      saveLoading: false,
      formChanges: false,
      editFormObj: {},
      id407: {
        maidAddress: " ",
        wage: " ",
        allowance: " ",
        withness1: " ",
        withness2: " ",
        housesize: " ",
        page4Other1: " ",
        page4Other2: " ",
        adultNum: " ",
        MinorNum: " ",
        babies: " ",
        minor518: " ",
        page3Yes: false,
        roomSize: " ",
        page3No: false,
        page3No1: false,
        page3No1ChildNum: " ",
        page3No1ChildAge: " ",
        page3No2: false,
        page3No2SQFT: " ",
        page3No3: false,
        page3No3DetailLine1: " ",
        page3No3DetailLine2: " ",
      },
    };
  },
};
</script>

<style>
</style>