<template>
  <v-card
    color="grey lighten-4"
    light
  >
    <v-card-text>
      <content-section title="簡介 Brief Introduction">
        {{helper.briefInfo}}
        <h4 class='mt-5'>Video Link :</h4>
        <p>
          {{helper.videoLink ? helper.videoLink : ""}}
        </p>
      </content-section>

      <content-section title="個人資料 Personal Particulars">
        <v-row>
          <v-col
            cols='4'
            :key='index'
            v-for='item,index in info.items'
          >

            <div>
              <v-icon>
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </div>
            <div v-if='item.name2'>
              {{ item.name2 }}
            </div>
            <h4>{{item.text}}</h4>
          </v-col>

        </v-row>
      </content-section>

      <content-section title="語言 Language">
        <v-row>
          <v-col cols='4'>
            <span>英文 English:</span>
            <v-progress-linear
              :value="formatScore(helper.english)"
              :color='color'
              class=''
              height="25"
            >
              <template v-slot:default="{}">
                <strong>{{formatLanauge(helper.english)}}</strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols='4'>
            <span>廣東話 Cantonese:</span>
            <v-progress-linear
              :value="formatScore(helper.cantonese)"
              :color='color'
              class=''
              height="25"
            >
              <template v-slot:default="{}">
                <strong>{{formatLanauge(helper.cantonese)}}</strong>
              </template>
            </v-progress-linear>
          </v-col>
          <v-col cols='4'>
            <span>普通話 Mandarin:</span>
            <v-progress-linear
              :value="formatScore(helper.mandarin)"
              :color='color'
              class=''
              height="25"
            >
              <template v-slot:default="{}">
                <strong>{{formatLanauge(helper.mandarin)}}</strong>
              </template>
            </v-progress-linear>
          </v-col>

        </v-row>

      </content-section>
      <content-section title="工作技能 Working Ability">
        <span>照顧幼兒 Care of Infant:</span>
        <v-progress-linear
          :value="formatScore(helper.careInfant)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.careInfant)}}</strong>
          </template>
        </v-progress-linear>
        <span>照顧小孩 Care of Child:</span>
        <v-progress-linear
          :value="formatScore(helper.careChild)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.careChild)}}</strong>
          </template>
        </v-progress-linear>
        <span>照顧老人 Care of Elderly:</span>
        <v-progress-linear
          :value="formatScore(helper.careElderly)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.careElderly)}}</strong>
          </template>
        </v-progress-linear>
        <span>照顧傷殘人仕 Care of Disabled Person:</span>
        <v-progress-linear
          :value="formatScore(helper.careDisabled)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.careDisabled)}}</strong>
          </template>
        </v-progress-linear>
        <span>照顧病人 Care of Bedridden Person:</span>
        <v-progress-linear
          :value="formatScore(helper.careBedridden)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.careBedridden)}}</strong>
          </template>
        </v-progress-linear>
        <span>照顧動物 Care of Pet:</span>
        <v-progress-linear
          :value="formatScore(helper.carePet)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.carePet)}}</strong>
          </template>
        </v-progress-linear>
        <span>烹飪 Cooking:</span>
        <v-progress-linear
          :value="formatScore(helper.cooking)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.cooking)}}</strong>
          </template>
        </v-progress-linear>
        <span>一般家務 General Housework (e.g. Cleaning & Ironing)::</span>
        <v-progress-linear
          :value="formatScore(helper.generalHousework)"
          :color='color'
          class=''
          height="25"
        >
          <template v-slot:default="{}">
            <strong>{{formatLanauge(helper.generalHousework)}}</strong>
          </template>
        </v-progress-linear>
      </content-section>

      <content-section
        v-if="helper.workExp"
        title="地區工作經驗"
        title2="Country Experience"
      >
        <v-row>
          <v-col
            cols='4'
            :key='index'
            v-for='item,index in info.countries'
          >
            <div>

              {{ item.name }}
            </div>
            <div v-if='item.name2'>
              {{ item.name2 }}
            </div>
            <h4>{{item.text}}</h4>
          </v-col>

        </v-row>
      </content-section>
      <div
        v-if="helper.workExp.length >= 2"
        style='height: 760px;'
      >
      </div>
      <content-section
        v-if="helper.workExp"
        title="家庭傭工工作經驗 (最近期排前)"
        title2="Working Experience as Domestic Helper(Most Recent First)"
      >
        <div
          class='mb-3'
          v-for="(exp, i) in helper.workExp"
          :key="i"
        >
          <p>
            地區 District: <strong>{{formatCountry(exp.country,exp)}}</strong>
          </p>
          <v-layout>
            <v-flex md12>
              <span v-if="exp.duration">任職日期 Working Period: <strong>{{ exp.duration }} years</strong></span>
              <div>
                <span>僱主家庭人數 Number of Employer's Family Members: <strong>{{ exp.houseHoldSize }}</strong></span>
              </div>
              <div>
                <span>房屋面積 House Size (SQFT): <strong>{{ exp.housesize ?  exp.housesize : ""}}</strong></span>
              </div>
              <div>
                離職原因 Reason to Leave: <strong>{{ exp.reasonToLeave }}</strong>
              </div>

              <h4>主要職務 Major Duties</h4>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.careInfant === "No"'
              >
                <v-icon left>
                  mdi-baby-carriage
                </v-icon>
                <b>照顧幼兒 Care of Infant</b>
              </v-btn>
              <span>{{exp.careInfantDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.careChild === "No"'
              >
                <v-icon left>
                  mdi-human-male-child
                </v-icon>
                照顧小孩 Care of Child
              </v-btn>
              <span>{{exp.careChildDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.careElderly === "No"'
              >
                <v-icon left>
                  mdi-human-cane
                </v-icon>
                照顧老人 Care of Elderly
              </v-btn>
              <span>{{exp.careElderlyDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.careDisabled === "No"'
              >
                <v-icon left>
                  mdi-human-wheelchair
                </v-icon>
                照顧傷殘人仕Care of Disabled Person
              </v-btn>
              <span>{{exp.careDisabledDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.careBedridden === "No"'
              >
                <v-icon left>
                  mdi-emoticon-sick-outline
                </v-icon>
                照顧病人 Care of Bedridden Person
              </v-btn>

              <v-btn
                :color='color'
                text
                small
                :disabled='exp.carePet === "No"'
              >
                <v-icon left>
                  mdi-paw
                </v-icon>
                照顧動物 Care of Pet
              </v-btn>
              <span>{{exp.carePetDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.cooking === "No"'
              >
                <v-icon left>
                  mdi-chef-hat
                </v-icon>
                烹飪 Cooking
              </v-btn>
              <span>{{exp.cookingDetail}}</span>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.generalHousework === "No"'
              >
                <v-icon left>
                  mdi-spray-bottle
                </v-icon>
                一般家務 General Housework (e.g. Cleaning & Ironing)
              </v-btn>
              <v-btn
                :color='color'
                text
                small
                :disabled='exp.carWashing === "No"'
              >
                <v-icon left>
                  mdi-car-wash
                </v-icon>
                洗車 Car Washing
              </v-btn>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </div>
      </content-section>
    </v-card-text>
  </v-card>
</template>

<script>
import ContentSection from "./Section";
export default {
  mounted() {
    if (this.$route.query.color) {
      this.color = "#" + this.$route.query.color;
    }
  },
  props: {
    helper: Object,
  },
  name: "MainContent",
  components: { ContentSection },
  computed: {
    hasIcon() {
      return !this.icon;
    },
  },
  methods: {
    formatCountry(value, expObj) {
      switch (value) {
        case "Hong Kong":
          return `${value} (${expObj.hongKongLocation})`;
        default:
          return value;
      }
    },
    formatLanauge(value) {
      switch (value) {
        case 4:
          return "非常好 Excellent";
        case 3:
          return "好 Good";
        case 2:
          return "一般  Fair";
        case 1:
          return "有待學習 Need to Learn";
      }
    },
    formatScore(value) {
      return value * 25;
    },
  },
  data() {
    return {
      color: "#4cb7b7",
      info: {
        title: "個人資料",
        title2: "Personal Particulars",
        items: [
          {
            name: "國籍 Nationality:",
            icon: "mdi-passport",
            text: this.helper.nationality,
          },
          {
            name: "年齡 Age: ",
            icon: "mdi-cake-variant-outline",
            text: `${this.$moment().diff(this.helper.dob, "years")} 
                ${
                  this.helper.Zodiac
                    ? "/" + this.helper.Zodiac + "/" + this.helper.chineseZodiac
                    : ""
                }`,
          },
          {
            name: "教育程度 Education Level: ",
            icon: "mdi-school",
            text: this.helper.education,
          },
          {
            name: "婚姻狀況 Marital Status:",
            icon: "mdi-home-heart",
            text: this.helper.maritalStatus,
          },
          {
            name: "宗教 Religion:",
            icon: "mdi-cross",
            text: this.helper.religion,
          },
          {
            name: "高度/體重 Height And Weight:",
            icon: "mdi-human-male-height",
            text: `${this.helper.height}cm/feet / ${this.helper.weight}kg`,
          },
          {
            name: "子女人數及年齡 Number of children and age:",
            icon: "mdi-human-male-child",
            text: this.helper.childenNumAndAge,
          },
          {
            name: "兄弟姐妹的數量和排名 Number of siblings and ranking:",
            icon: "mdi-baby-carriage ",
            text: this.helper.siblingsNumAndRanking,
          },
        ],
        countries: [
          {
            name: "香港 Hong Kong",

            text: this.helper.workRecord.hk,
          },
          {
            name: "中東 Middle East",
            text: this.helper.workRecord.middleEast,
          },
          {
            name: "新加坡 Singapore",
            text: this.helper.workRecord.singapore,
          },
          {
            name: "台灣 Taiwan",
            text: this.helper.workRecord.taiwan,
          },
          {
            name: "印尼 Indonesia",
            text: this.helper.workRecord.indonesia,
          },
          {
            name: "菲律賓 Philippines",
            text: this.helper.workRecord.philippines,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.title {
  border-bottom: 2px #bfbfbf solid;
  line-height: 1.5 !important;
}
.progress {
  margin-top: 0.1rem;
}
</style>
