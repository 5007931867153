<template>
  <v-app>
    <h1>Dashboard</h1>
    <v-container
      fluid
      text-xs-center
    >
      <v-layout wrap>
        <v-flex
          v-if='claims.role === "franchisee"'
          xs12
          sm4
          order-md2
          order-sm1
          pa-2
        >
          <v-card
            link
            :to='"/helper/all?type=myHelpers"'
            class='text-center'
            dark
            tile
            flat
            color="blue lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{helpers}}</v-card-text>
            <v-card-text class="display-1 text-center">My Helpers</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md2
          order-sm1
          pa-2
        >
          <v-card
            link
            :to='"/helper/all"'
            class='text-center'
            dark
            tile
            flat
            color="red lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{allHelpers}}</v-card-text>
            <v-card-text class="display-1 text-center">All Helpers</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          v-if='claims.role !== "franchisee"'
          sm4
          order-md3
          order-sm2
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/helper/matchedresults")}'
            class='text-center'
            dark
            tile
            flat
            color="orange lighten-1"
            hover
          >
            <v-card-text class="display-2 text-center">{{inquries}}</v-card-text>
            <v-card-text class="display-1 text-center">Inquries</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md4
          order-sm3
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/helper/applications")}'
            class='text-center'
            dark
            tile
            flat
            color="green"
            hover
          >
            <v-card-text class="display-2 text-center">{{applications}}</v-card-text>
            <v-card-text class="display-1 text-center">Applications</v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          sm4
          order-md4
          order-sm3
          pa-2
        >
          <v-card
            @click='()=>{$router.push("/cases/all")}'
            class='text-center'
            dark
            tile
            flat
            color="purple"
            hover
          >
            <v-card-text class="display-2 text-center">{{cases}}</v-card-text>
            <v-card-text class="display-1 text-center">Cases</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { getDashboardData } from "../dataservices/appSettingsDataservices";

export default {
  name: "Home",
  async mounted() {
    const refs = await getDashboardData();
    if (this.claims.role !== "admin") {
      const docs = await refs.helperRef.get();
      this.helpers = docs.docs.length;
    }
    this.allHelpers = "1000+";

    const applicationref = await refs.registerRef.get();
    this.applications = applicationref.docs.length;
    const MatchResultsWithContactInfoRef = await refs.MatchResultsWithContactInfoRef.get();
    this.inquries = MatchResultsWithContactInfoRef.docs.length;
    const casesRef = await refs.casesRef.get();
    this.cases = casesRef.docs.length;

  },

  data() {
    return {
      allHelpers: 0,
      cases: 0,
      helpers: 0,
      applications: 0,
      inquries: 0,
    };
  },
  components: {},
  computed: {
    ...mapFields(["claims"]),
  },
};
</script>
