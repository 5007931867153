<template>

  <v-stepper class='mt-10'
    v-model="wizard"
    vertical
  >
    <v-stepper-step
      step="1"
      :complete='wizard >= 1'
    >
    Step 1

    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
        class="mb-12"
      >
      <div class='ma-3'>
      <v-checkbox
      prepend-icon='mdi-baby-carriage'
      v-model="skillset"
      value="careInfant"
      label="care Infant"
    >
    </v-checkbox>
    <v-checkbox
     value="careChild"
      v-model="skillset"
      prepend-icon='mdi-human-male-child'
      label="care Child"
    ></v-checkbox>
    <v-checkbox
     value="careElderly"
      v-model="skillset"
      prepend-icon='mdi-human-cane'
      label="care Elderly"
    ></v-checkbox>
    <v-checkbox
     value="careDisabled"
      v-model="skillset"
      prepend-icon='mdi-human-wheelchair'
      label="care Disabled"
    ></v-checkbox>
    <v-checkbox
     value="careBedridden"
      v-model="skillset"
      prepend-icon='mdi-emoticon-sick-outline'
      label="care Bedridden"
    ></v-checkbox>
    <v-checkbox
     value="carePet"
      v-model="skillset"
      prepend-icon='mdi-paw'
      label="care Pet"
    ></v-checkbox>
    <v-checkbox
     value="cooking"
      v-model="skillset"
       prepend-icon='mdi-chef-hat'
      label="cooking"
    ></v-checkbox>
    <v-checkbox
     value="generalHousework"
      v-model="skillset"
       prepend-icon='mdi-spray-bottle'
      label="general Housework"
    ></v-checkbox>
        <v-checkbox
     value="carWashing"
      v-model="skillset"
       prepend-icon='mdi-car-wash'
      label="car Washing"
    ></v-checkbox>
      </div>
      </v-card>
      <v-btn
        color="primary"
        @click="validateStepOne"
      >
        continue
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      step="2"
      :complete='wizard >= 2'
    >
      Step 2
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        class="mb-12"
      >
      <div class='ma-3'>
       <v-checkbox
      v-model="personality"
      label="cleanliness"
      value="cleanliness"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="discipline"
      value="discipline"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="empathy"
      value="empathy"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="efficiency"
      value="efficiency"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="friendliness"
      value="friendliness"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="hardworking"
      value="hardworking"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="honesty"
      value="honesty"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      label="patience"
      value="patience"
    ></v-checkbox>
     <v-checkbox
      v-model="personality"
      label="proactiveness"
      value="proactiveness"
    ></v-checkbox>
     <v-checkbox
      v-model="personality"
      label="responsible"
      value="responsible"
    ></v-checkbox>
      </div>
      </v-card>
      <v-btn
        color="primary"
        @click="validateStepTwo"
      >
      continue
      </v-btn>
      <v-btn @click="wizard = 1"  text>
       back
      </v-btn>
    </v-stepper-content>


    <v-stepper-step :complete='wizard >= 3' step="3">
      Step 3
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card
        class="mb-12"
        elevation='0'
      >
      <div class='ma-3'>
        <v-form
        ref="form"
    v-model="valid"
    lazy-validation>
            <v-select
                 :items="['1-2','3-4','5+']"
                  label="familyMember"
                  v-model="familyMember"
                  :rules="requiredRule"
                  required
                ></v-select>
                  <v-select
                  :items="yesno"
                   label="prevExp"
                   :rules="requiredRule"
                  v-model="prevExp"
                  required
                ></v-select>
                  <v-select
                  :items="yesno"
                   label="privateRoom"
                   :rules="requiredRule"
                  v-model="privateRoom"
                  required
                ></v-select>
        </v-form>
      </div>
      </v-card>
      <v-card-actions>
        <v-btn
        :loading='btnloading'
        color="primary"
        @click="complete"
      >
        Add to Advance Search
      </v-btn>
      <v-btn @click="wizard = 2"  text>
        back
      </v-btn>
      </v-card-actions>
      
    </v-stepper-content>

       

    
  </v-stepper>



</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
       computed: {
    ...mapFields([
      'frontEndMatch',
    ]),

  },
    methods : {
              validateStepOne(){
            if(this.skillset.length > 0 ){
                 this.wizard = 2;
            }else{
                alert("need to select at least 1 skill");
                return;
            }
           
        },
        validateStepTwo(){
            if(this.personality.length <= 5){
                 this.wizard = 3;
            }else{
                alert("need at least 1 and cant exceed 5 personality");
                return;
            }
           
        },
        reset(){
          this.wizard = 1;
          this.apiresult = [];
          this.helperType = null;
          this.privateRoom = null;
          this.prevExp = null;
          this.familyMember = 0;
          this.skillset = [];
          this.personality= [];
        this.careInfant = null;
        this.careChild = 0;
        this.careElderly = 0;
        this.careDisabled= 0;
        this.careBedridden = 0;
        this.carePet = 0;
        this.cooking = 0;
        this.generalHousework = 0;
        this.carWashing = 0;
        },
        async complete(){
          let valid = this.$refs.form.validate();
            if(valid){
              this.frontEndMatch = {
                   skillset : this.skillset,
             personality  : this.personality,
             familyMember : this.familyMember,
              helperType : this.helperType,
             privateRoom : this.privateRoom,
              prevExp : this.prevExp
              }
            
            this.btnloading = false;
            this.wizard = 3;
        }
            }
            
    },
    name: "FaqTwo",
     data () {
      return {
          yesno : ["yes","no"],
        allResult : 0,
        btnloading : false,
        matchid : null,
        requiredRule : [ v => !!v || 'This Field is required'],
        apiresult : [],
        valid : true,
          helperType : null,
          privateRoom : null,
          prevExp : null,
          familyMember : 0,
          skillset : [],
          personality: [],
        wizard: 1,
        careInfant : null,
        careChild : 0,
        careElderly : 0,
        careDisabled: 0,
        careBedridden : 0,
        carePet : 0,
        cooking : 0,
        generalHousework : 0,
        carWashing : 0,
      }
    },
};
</script>

<style>
</style>