import { db } from "../firebase/firebaseConfig";
import store from "../store/index";
import axios from "axios";
export const getAllApplications = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db.collection("register").orderBy("submitDate", "desc");
	} else {
		return db
			.collection("register")
			.where("franchiseeId", "==", claims.franchiseeId)
			.orderBy("submitDate", "desc");
	}
};

export const getFranImg = async (id) => {
	const result = await axios.get(
		"https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/getFranImage/" +
			id
	);
	return result.data;
};

export const completeReview = async (combineData) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		combineData.franchiseeId = "";
	} else {
		combineData.franchiseeId = claims.franchiseeId;
	}
	return await db.collection("Helpers").doc(combineData.id).set(combineData);
};
