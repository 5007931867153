<template>
  <v-container>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">Franchisee Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if='selectedItem'>
              <v-col cols="12">
                <v-text-field
                  label="Id"
                  disabled
                  v-model='selectedItem.id'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  v-model='selectedItem.email'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Address"
                  v-model='selectedItem.address'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Franchisee Code"
                  v-model='selectedItem.franchiseeCode'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model='selectedItem.name'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone Number"
                  v-model='selectedItem.phoneNumber'
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Logo</span>
                <v-img
                  alt="Vuetify Logo"
                  class="shrink mr-2 mt-5"
                  contain
                  :src="`data:image/png;base64,${selectedItem.base64}`"
                  transition="scale-transition"
                  width="250"
                />
                <v-btn
                  class='mt-5'
                  @click='upload'
                >Upload Photo</v-btn>
                <input
                  id='fileid'
                  type='file'
                  @change="Handlechange"
                  hidden
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateFranchisee"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this Franchisees?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>
    <h2>Manage Franchisees</h2>
    <v-card>
      <v-card-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading='loading'
        loading-text="Loading data... Please wait"
        :headers="headers"
        :items="result"
        :search="search"
        multi-sort
      >
        <template v-slot:item.base64="{ item }">
          <img
            v-if='item.base64'
            :src="`data:image/png;base64,${item.base64}`"
            height='30'
          />
          <span v-else>No Image Yet</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            small
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            class='ml-2'
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../firebase/firebaseConfig";
export default {
  mounted() {
    db.collection("Franchisees").onSnapshot((docs) => {
      this.result = docs.docs.map((doc) => doc.data());
      this.loading = false;
    });
  },
  methods: {
    async Handlechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.selectedItem.base64 = image;
        this.selectedItem.base64 = this.selectedItem.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;
      };
      reader.readAsDataURL(file);
    },
    upload() {
      document.getElementById("fileid").click();
    },
    async updateFranchisee() {
      await db
        .collection("Franchisees")
        .doc(this.selectedItem.id)
        .set(this.selectedItem, { merge: true });
      this.dialog = false;
    },
    async confirmDelete() {
      this.dialog2 = false;
      await db.collection("Franchisees").doc(this.selectedItem).delete();
      this.selectedItem = null;
      alert("Franchisee Deleted");
    },
    async deleteItem(item) {
      this.selectedItem = item.id;
      this.dialog2 = true;
    },
    async editItem(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
  },
  data() {
    return {
      fileName: null,
      fileType: null,
      uploading: false,
      dialog: false,
      selectedItem: null,
      dialog2: false,
      loading: true,
      headers: [
        { text: "Id", value: "id" },
        { text: "Franchisee Code", value: "franchiseeCode" },
        { text: "Logo", value: "base64" },
        { text: "Email", value: "email" },
        { text: "Name", value: "name" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Action", value: "action" },
      ],
      result: [],
      search: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Manage Franchisees",
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>

<style>
</style>