import { db } from "../firebase/firebaseConfig";
import store from "../store/index";
const { collection, query, where, and, or } = require("firebase/firestore");

export const deleteMessageById = async (id) => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return await db.collection("Messages").doc(id).delete();
	} else {
		return "";
	}
};
export const getAllMessages = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return db.collection("Messages").orderBy("createTime", "desc");
	} else {
		let r = query(
			collection(db, "Messages"),
			and(
				or(
					where("toId", "==", claims.franchiseeId),
					where("toId", "==", "Admin")
				),

				or(
					where("fromId", "==", "Admin"),
					where("fromId", "==", claims.franchiseeId)
				)
			)
		);

		return r;
	}
};
