<template>
  <div>

    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-row v-if='result'>

      <v-col
        offset-md="2"
        cols=12
      >

        <span>User Selected Skill(s): </span>

        <v-chip
          :key='index'
          v-for='skill,index in result.skillset'
          class="ma-2"
        >

          {{skill}}

        </v-chip>

      </v-col>

      <v-col
        offset-md="3"
        cols=12
      >

        <v-icon x-large>mdi-arrow-down-bold</v-icon>

      </v-col>

      <v-col
        offset-md="1"
        cols=12
      >

        <span>Database returns helpers with skill >= 3 </span>

        <v-chip
          :key='index'
          v-for='skill,index in result.skillset'
          class="ma-2"
        >

          {{skill}}

          <v-avatar class="green ml-1">

            {{debug.fromDB[index]}}

          </v-avatar>

        </v-chip>

      </v-col>

      <v-col
        offset-md="3"
        cols=12
      >

        <v-icon x-large>mdi-arrow-down-bold</v-icon>

      </v-col>

      <v-col
        offset-md="1"
        cols=12
      >

        <span>Remove all Duplicate helper</span>

        <v-chip class="ma-2">

          Helpers

          <v-avatar class="green ml-1">

            {{debug.unique.length}}

          </v-avatar>

        </v-chip>

      </v-col>

      <v-col
        offset-md="3"
        cols=12
      >

        <v-icon x-large>mdi-arrow-down-bold</v-icon>

      </v-col>

      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Helpers that does not qualify skills score >=3 ({{debug.filteredOutSkills.length}}) helpers left</span>

        <v-row>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.filteredOutSkills'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip
                :key='index'
                v-for='skill,index in result.skillset'
                class="ma-2"
              >

                {{skill}}

                <v-avatar class="green ml-1">

                  {{helper[skill]}}

                </v-avatar>

              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>

      <v-col
        offset-md="3"
        cols=12
      >

        <v-icon x-large>mdi-arrow-down-bold</v-icon>

      </v-col>

      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Not Valid Helper ({{debug.filterNotValid.length}}) helpers left</span>

        <v-row>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.filterNotValid'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip class="ma-2">

                Valid:

                <v-avatar class="green ml-1">

                  {{helper.valid}}

                </v-avatar>

              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>
      <v-col
        offset-md="3"
        cols=12
      >
        <v-icon x-large>mdi-arrow-down-bold</v-icon>
      </v-col>
      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Helpers base on helper type <b>{{result.helperType}} (skip if selected 'Both')</b> <span v-if='result.helperType !== "both"'>({{debug.filterHelperType.length}}) helpers left</span></span>

        <v-row>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.filterHelperType'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip class="ma-2">

                Helper Type: {{helper.status}}
              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>
      <v-col
        offset-md="3"
        cols=12
      >
        <v-icon x-large>mdi-arrow-down-bold</v-icon>
      </v-col>
      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Helpers base on Private Room Preferred: <b>{{result.privateRoom}}.</b> <span v-if='result.privateRoom === "Yes"'>({{debug.filterPrivateRoom.length}}) helpers left</span><span v-else>(No Filter needed if answer is Yes)</span></span>

        <v-row v-if='result.privateRoom === "Yes"'>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.filterPrivateRoom'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip class="ma-2">

                Can Share Room?: {{helper.roomShare}}
              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>
      <v-col
        offset-md="3"
        cols=12
      >
        <v-icon x-large>mdi-arrow-down-bold</v-icon>
      </v-col>
      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Helpers base on Family Size Preferred: <b>{{result.familyMember}}.({{debug.filterFamilySize.length}}) helpers left</b></span>

        <v-row>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.filterFamilySize'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip class="ma-2">

                Family Size: {{helper.preferredfamilysize}}
              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>
      <v-col
        offset-md="3"
        cols=12
      >
        <v-icon x-large>mdi-arrow-down-bold</v-icon>
      </v-col>
      <v-col
        offset-md="1"
        cols=12
      >

        <span>Filtered Helpers base on Strength >= 8: <b>({{debug.final.length}}) helpers left</b></span>

        <v-row>

          <v-col
            cols='3'
            :key='index'
            v-for='helper,index in debug.final'
          >

            <v-card outlined>

              <v-list-item three-line>

                <v-list-item-content>

                  <div class="text-overline mb-4">

                    ID: {{helper.portfolioId}}

                  </div>

                  <v-list-item-title class="text-overline mb-1">

                    Name: {{helper.name}}

                  </v-list-item-title>

                </v-list-item-content>

                <v-list-item-avatar
                  v-if='!helper.fileLocation'
                  tile
                  size="80"
                  color="grey"
                ></v-list-item-avatar>
                <img
                  width='80'
                  v-else
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${helper.fileLocation}`'
                />

              </v-list-item>

              <v-chip
                :key='index'
                v-for='personality,index in result.personality'
                class="ma-2"
              >

                {{personality}}

                <v-avatar class="green ml-1">

                  {{helper[personality]}}

                </v-avatar>

              </v-chip>

            </v-card>

          </v-col>

        </v-row>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import { db } from "../firebase/firebaseConfig";

export default {
  async mounted() {
    db.collection("MatchResults")
      .where("id", "==", this.$route.params.id)
      .onSnapshot((docs) => {
        this.result = docs.docs.map((doc) => doc.data())[0];

        if (
          this.result.debug &&
          !this.result.debug.includes("requestDebugFile/")
        ) {
          this.debug = JSON.parse(this.result.debug);
        } else if (this.result.debug) {
          let url =
            "https://storage.googleapis.com/starjasmine-aacba.appspot.com/" +
            this.result.debug;
          fetch(url)
            .then((r) => r.text())
            .then((t) => {
              this.debug = JSON.parse(t);
            });
        }
      });
  },
  data() {
    return {
      debug: null,
      result: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Match Results",
          disabled: false,
          href: "/matchresults",
        },
        {
          text: `Match Result: ${this.$route.params.id}`,
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>