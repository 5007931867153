<template>
  <div class="text-center">
    <v-dialog
      persistent
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          New Message
        </v-card-title>

        <v-card-text class='mt-4'>
          <v-select
            v-if='role && role === "admin"'
            solo
            v-model='receipent'
            label='Pleace Select a receipent'
            :items='franchaisees'
            item-text='name'
            item-value="id"
          >
          </v-select>
          <v-text-field
            label='Subject'
            v-model='subject'
            solo
          >
          </v-text-field>

          <v-textarea
            solo
            rows='5'
            v-model='message'
            placeholder="Please Enter Message Here"
          >
          </v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading='loading'
            @click="sendMessage"
          >
            Send Message
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import momentTz from "moment-timezone";
import { getAllFranchisees } from "../dataservices/adminDataservices";
import store from "../store/index";
import { db } from "../firebase/firebaseConfig";
export default {
  async mounted() {
    this.role = store.getters.role;

    if (this.role === "admin") {
      this.franchaisees = await getAllFranchisees();
    }
  },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      role: null,
      loading: false,
      franchaisees: [],
      receipent: "",
      message: "",
      subject: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async sendMessage() {
      const claims = store.getters.claims;
      this.loading = true;
      let ref = db.collection("Messages").doc();
      if (this.role === "admin") {
        await ref.set({
          createTime: momentTz()
            .tz("Asia/Hong_Kong")
            .format("DD/MM/YYYY HH:mm:ss"),
          subject: this.subject,
          id: ref.id,
          fromId: "Admin",
          toId: this.receipent,
          from: "Admin",
          to: this.franchaisees.find((f) => f.id === this.receipent).name
            ? this.franchaisees.find((f) => f.id === this.receipent).name
            : "",
          conversation: [
            {
              from: "Admin",
              time: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY HH:mm:ss"),
              message: this.message,
            },
          ],
        });
      } else {
        await ref.set({
          createTime: momentTz()
            .tz("Asia/Hong_Kong")
            .format("DD/MM/YYYY HH:mm:ss"),
          subject: this.subject,
          id: ref.id,
          fromId: claims.franchiseeId,
          toId: "Admin",
          from: claims.franchiseeId,
          to: "Admin",
          conversation: [
            {
              from: claims.franchiseeId,
              time: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY HH:mm:ss"),
              message: this.message,
            },
          ],
        });
      }
      this.$emit("close");
    },
  },
};
</script>