<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this profile?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        Domestic Helpers Applications
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading='loading'
        loading-text="Loading data... Please wait"
        :headers="headers"
        :items="result"
        :search="search"
      >
        <template v-slot:item.id="{ item }">
          <a @click='()=> {$router.push(`/application/${item.id}`)}'>{{item.id}}</a>
        </template>
        <template v-slot:item.image="{ item }">
          <img
            v-if='item.fileLocation'
            :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${item.fileLocation}`'
            width='80'
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>


<script>
import { db } from "../firebase/firebaseConfig";
import { getAllApplications } from "../dataservices/regDataservices";
export default {
  data: () => ({
    dialog2: false,
    loading: true,
    dialog: false,
    search: null,
    result: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Portfolio Id", value: "portfolioId" },
      { text: "Image", value: "image" },
      { text: "Name", value: "name" },
      { text: "Age", value: "age" },
      { text: "Date of Birth", value: "dob" },
      { text: "Type", value: "status" },
      { text: "Submission Date", value: "submitDate" },
      { text: "Availability (Date)", value: "availabilityDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Domestic helpers Applications",
        disabled: true,
        href: "",
      },
    ],
  }),
  methods: {
    async confirmDelete() {
      this.dialog2 = false;
      await db.collection("register").doc(this.selectedDeleteItem).delete();
      this.selectedDeleteItem = null;
      alert("Profile Deleted");
    },
    async deleteItem(item) {
      this.selectedDeleteItem = item.id;
      this.dialog2 = true;
    },
  },
  async mounted() {
    let query = await getAllApplications();

    query.onSnapshot((docs) => {
      this.result = docs.docs.map((doc) => doc.data());
      this.loading = false;
    });
  },
};
</script>
