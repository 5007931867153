import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@/firebase/firebaseConfig";
import { auth } from "@/firebase/firebaseConfig";
import axios from "axios";
import firebase from "firebase/compat/app";
import moment from "moment";
axios.interceptors.request.use(
	async (request) => {
		// Do something before request is sent
		let t = "";
		try {
			t = await firebase.auth().currentUser.getIdToken(true);
		} catch {
			t = "";
		}

		request.headers["Authorization"] = `Bearer ${t}`;
		return request;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
let app;
auth.onAuthStateChanged(async (user) => {
	if (user) {
		let { claims } = await firebase.auth().currentUser.getIdTokenResult(true);
		store.commit("set_claims", claims);
		store.commit("set_role", claims.role);
		store.dispatch("fetchUser", user);
	}
	if (!app) {
		app = new Vue({
			store,
			router,
			vuetify,
			render: (h) => h(App),
		}).$mount("#app");
	}
});
