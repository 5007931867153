<template>

  <v-card>
    <v-card-title>
      Interview Request Detail
    </v-card-title>
    <v-card-text>
      <v-form v-if='interviewData'>
        <v-text-field
          v-model='interviewData.id'
          label='id'
          disabled
        >
        </v-text-field>
        <v-text-field
          v-model='interviewData.createTime'
          label='createTime'
          disabled
        >
        </v-text-field>

        <v-text-field
          v-model='interviewData.franchiseeCode'
          label='Franchisee'
          readonly
        >

        </v-text-field>

        <v-text-field
          v-model='interviewData.caseId'
          label='case number'
          readonly
        >
          <template v-slot:append-outer>
            <v-btn @click='openLink(interviewData.caseId,"case")'>
              View Case
            </v-btn>
          </template>
        </v-text-field>

        <v-text-field
          v-model='interviewData.maidPortfolioId'
          label='maid Portfolio Id'
          readonly
        >
          <template v-slot:append-outer>
            <v-btn @click='openLink(interviewData.maidId,"maid")'>
              View Maid
            </v-btn>
          </template>

        </v-text-field>
        <v-text-field
          v-model='interviewData.requestTime'
          label='Request Detail'
          disabled
        >
        </v-text-field>
        <v-select
          v-model='interviewData.status'
          label='status'
          :items='["requested","scheduled","completed","canceled"]'
        >
        </v-select>
        <p>Meeting Link : <a
            target='blank'
            v-if="interviewData.meetingLink"
            :href='"https://starjasmine-aacba.web.app/meeting/"+interviewData.meetingLink'
          >https://starjasmine-aacba.web.app/meeting/{{interviewData.meetingLink}}</a></p>
        <v-btn
          @loading='loading'
          @click='generateLink'
          color='primary'
        >
          Generate Meeting Link
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  getInterviewById,
  updateInterviewData,
} from "../dataservices/interviewDataservices";
import { generateNewMeetingLink } from "../dataservices/adminDataservices";
export default {
  methods: {
    openLink(id, type) {
      let routeData;
      if (type === "maid") {
        routeData = this.$router.resolve({ name: `/helpers/${id}` });
      } else if (type === "case") {
        routeData = this.$router.resolve({ name: `/case/${id}` });
      } else {
        routeData = this.$router.resolve({ name: `/admin/interview/${id}` });
      }

      window.open(routeData.route.name, "_blank");
    },
    async generateLink() {
      this.loading = true;
      let linkData = await generateNewMeetingLink();
      await updateInterviewData(this.interviewData.id, linkData.data.meetingId);
      this.loading = false;
      location.reload();
    },
  },
  async mounted() {
    let query = await getInterviewById(this.$route.params.id);
    query.onSnapshot((docs) => {
      this.interviewData = docs.docs.map((doc) => doc.data())[0];
    });
  },
  data() {
    return {
      interviewData: null,
      loading: false,
    };
  },
};
</script>

<style>
</style>