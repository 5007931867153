<template>
  <v-row>
    <v-col cols='4'>
      <v-card>
        <v-card-title>
          Download Forms
        </v-card-title>
        <v-card-text>

          <v-tabs v-model="tab">
            <v-tab href="#tab-1">勞工署表格</v-tab>
            <v-tab href="#tab-2">Other PDF Forms</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-row>
                <v-col cols='12'>

                </v-col>
                <v-list-item
                  v-for="file in files"
                  :key="file.title"
                >
                  <v-list-item-avatar>
                    <v-icon
                      :class="file.color"
                      dark
                      v-text="file.icon"
                    ></v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{file.title}}
                      <v-btn
                        class='mb-1'
                        @click='editForm(file.id)'
                        icon
                      >
                        <v-icon title='edit'>mdi-pencil</v-icon>
                      </v-btn>

                    </v-list-item-title>

                    <v-list-item-subtitle> </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-col>
                      <v-btn
                        @click='genPreview(file.id)'
                        icon
                      >
                        <v-icon title='preview document'>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn
                        @click='downloadPDF'
                        icon
                      >
                        <v-icon title='download'>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-list-item-action>
                </v-list-item>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-list dense>
                <v-list-item
                  v-for="file in downloadOnlyFile"
                  :key="file.url"
                >
                  <v-list-item-avatar>
                    <v-icon
                      class="blue"
                      dark
                    >mdi-clipboard-text</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-col>
                      {{file.fileName}}
                      <v-btn
                        @click='genpreviewURl(file.url)'
                        icon
                      >
                        <v-icon title='preview document'>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn
                        :href='file.url'
                        target='_blank'
                        icon
                      >
                        <v-icon title='download'>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-list-item-content>
                </v-list-item>

              </v-list>

            </v-tab-item>

          </v-tabs-items>

        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols='8'>
      <iframe
        v-if='previewMode'
        width='100%'
        height='1100px'
        :src='contractBlob'
      />
      <v-card v-if='editMode'>
        <v-card-title>
          Edit Form
        </v-card-title>
        <v-card-text>

          <id407Form
            v-if='editFormId === "id407"'
            :caseData="caseObj"
            :maidData="maidObj"
          />
          <owwaForm
            v-if='editFormId === "owwa"'
            :caseData="caseObj"
            :maidData="maidObj"
          />
          <ofwForm
            v-if='editFormId === "ofwInfo"'
            :caseData="caseObj"
            :maidData="maidObj"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { downloadOnlyFile } from "../files";
import id407Form from "./forms/id407.vue";
import owwaForm from "./forms/owwa.vue";
import ofwForm from "./forms/ofw.vue";
import { db } from "../firebase/firebaseConfig";
import momentTz from "moment-timezone";
import {
  serviceAgreementEng,
  serviceAgreementChinese,
  contractAgencyER_Renewal_Eng,
  contractAgencyER_Renewal_Chinese,
  serviceAgreementLocalEng,
  serviceAgreementLocalChinese,
  serviceAgreementERChinese,
  serviceAgreementEREng,
  id407,
  owwa,
  ofwInfo,
} from "../forms/forms";
import { getFranchiseeById } from "../dataservices/adminDataservices";
import { genContractPreviewBlob, OpenPreivegenContractPreview } from "../util";
export default {
  props: {
    caseObj: Object,
    maidObj: Object,
  },
  components: {
    id407Form,
    owwaForm,
    ofwForm,
  },
  async mounted() {
    this.editFormObj = this.caseObj;
    this.franchisee = await getFranchiseeById(this.caseObj.bio.franchiseeId);
    let adata = await db
      .collection("Agents")
      .doc(this.caseObj.bio.agentId)
      .get();
    this.agent = adata.data();
  },
  methods: {
    genpreviewURl(url) {
      this.contractBlob = url;
      this.previewMode = true;
    },
    editForm(id) {
      this.previewMode = false;
      this.editMode = true;
      this.editFormId = id;
    },
    async downloadPDF() {
      this.genInput();
      await OpenPreivegenContractPreview(this.inputs, this.template);
    },
    genInput() {
      const date = this.caseObj.contractSignDate;
      console.log(this.caseObj.bio.customerAddress);
      switch (this.editFormId) {
        case "serviceAgreementEng":
          this.inputs = [
            {
              client_name_page4: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerMiddleName} ${this.caseObj.bio.customerFirstName}`,
              client_name_page3: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerMiddleName} ${this.caseObj.bio.customerFirstName}`,
              engName_page3: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerMiddleName} ${this.caseObj.bio.customerFirstName}`,
              english_name: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerMiddleName} ${this.caseObj.bio.customerFirstName}`,
              chinese_name: "",
              date: date,
              date_page4: date,
              date_page3: date,
              date_page4_2: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY"),
              date_page3_2: momentTz()
                .tz("Asia/Hong_Kong")
                .format("DD/MM/YYYY"),
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              helper_name: this.maidObj.name,
              helper_nationality: this.maidObj.nationality,
              helper_passportNum: this.maidObj.passportNum,
              FranName: this.franchisee.name,
              FranNameHeader: this.franchisee.name,
              FranemailHeader: this.franchisee.email,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              CustomerNameHeader: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              companyName_page3: this.franchisee.name,
              salesRepName: this.agent.agentName,
              salesRepNamepage3: this.agent.agentName,
              fee: this.caseObj.agencyFee,
              position: "",
              positionPage3: "",
            },
          ];
          break;
        case "serviceAgreementChinese":
          this.inputs = [
            {
              date: date,
              FranNameHeader: this.franchisee.name,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranemailHeader: this.franchisee.email,
              FranName: this.franchisee.name,
              fee: this.caseObj.agencyFee,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              engName_page3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              date_page3_1: date,
              date_page3_2: date,
              agentName_page3: this.agent.agentName,
              agentName_page3_2: this.agent.agentName,
              engName_page3_2: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              date_page3_3: date,
              date_page3_4: date,
              madiNamePage3: this.maidObj.name,
              maidNationPage3: this.maidObj.nationality,
              maidPassportPage3: this.maidObj.passportNum,
            },
          ];
          break;
        case "contractAgencyER_Renewal_Eng":
          this.inputs = [
            {
              date: date,
              FranNameHeader: this.franchisee.name,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranemailHeader: this.franchisee.email,
              FranName: this.franchisee.name,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              fee: this.caseObj.agencyFee,
              engName_page2: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              "engName_page2 copy": this.agent.agentName,
              date_page2: date,
              date_page2_1: date,
              engName_page3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              comapny_name_page3: this.franchisee.name,
              agentName_page3_2: this.agent.agentName,
              maid_name: this.maidObj.name,
              maid_nationality: this.maidObj.nationality,
              maid_passport: this.maidObj.passportNum,
              date_page3_3: date,
              date_page3_4: date,
            },
          ];
          break;
        case "contractAgencyER_Renewal_Chinese":
          this.inputs = [
            {
              date: date,
              FranNameHeader: this.franchisee.name,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranemailHeader: this.franchisee.email,
              FranName: this.franchisee.name,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              fee: this.caseObj.agencyFee,

              english_name_page3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agent_page3: this.agent.agentName,
              date_page3: date,
              date_page3_2: date,
              maid_name: this.maidObj.name,
              maid_nationality: this.maidObj.nationality,
              maid_passport: this.maidObj.passportNum,

              engname_page4: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agentname_page4: this.agent.agentName,

              date_page4: date,
              date_page4_2: date,
            },
          ];
          break;
        case "serviceAgreementLocalEng":
          this.inputs = [
            {
              date: date,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              FranemailHeader: this.franchisee.email,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranNameHeader: this.franchisee.name,
              FranName: this.franchisee.name,
              fee: this.caseObj.agencyFee,
              maid_name: this.maidObj.name,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agentName_page3_2: this.agent.agentName,
              date_page3_3: date,
              date_page3_4: date,
              date_page4: date,
              date_page4_2: date,
              eng_name_page4: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agent_name_page4: this.agent.agentName,
              maid_name_page4: this.maidObj.name,
              maid_nationality_page4: this.maidObj.nationality,
              maid_passport_page4: this.maidObj.passportNum,
            },
          ];
          break;
        case "serviceAgreementLocalChinese":
          this.inputs = [
            {
              date: date,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              FranemailHeader: this.franchisee.email,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranNameHeader: this.franchisee.name,
              FranName: this.franchisee.name,
              fee: this.caseObj.agencyFee,
              fee_30_percent: (
                parseInt(this.caseObj.agencyFee) -
                (30 / 100) * parseInt(this.caseObj.agencyFee)
              ).toString(),
              maid_name: this.maidObj.name,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agentName_page3_2: this.agent.agentName,
              date_page3_3: date,
              date_page3_4: date,
              date_page4: date,
              date_page4_2: date,
              eng_name_page4: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agent_name_page4: this.agent.agentName,
              maid_name_page4: this.maidObj.name,
              maid_nationality_page4: this.maidObj.nationality,
              maid_passport_page4: this.maidObj.passportNum,
              eng_name_page3_2: this.maidObj.name,
            },
          ];
          break;
        case "serviceAgreementERChinese":
          this.inputs = [
            {
              date: date,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              FranemailHeader: this.franchisee.email,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranNameHeader: this.franchisee.name,
              FranName: this.franchisee.name,
              fee: this.caseObj.agencyFee,
              maid_name: this.maidObj.name,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agentName_page3_2: this.agent.agentName,
              date_page3_3: date,
              date_page3_4: date,
              date_page4: date,
              date_page4_2: date,
              eng_name_page4: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agent_name_page4: this.agent.agentName,
              "maid_name_page4 copy": this.maidObj.name,
              "maid_nationality_page4 copy": this.maidObj.nationality,
              "maid_passport_page4 copy": this.maidObj.passportNum,
              eng_name_page3_2: this.maidObj.name,
            },
          ];

          break;
        case "serviceAgreementEREng":
          this.inputs = [
            {
              date: date,
              chinese_name: "",
              english_name: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              phoneNum: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              email: this.caseObj.bio.emailAddress,
              address: this.caseObj.bio.customerAddress,
              FranemailHeader: this.franchisee.email,
              FranAddressHeader: this.franchisee.address
                ? this.franchisee.address
                : "",
              FranphoneNumHeader: this.franchisee.phoneNumber,
              FranNameHeader: this.franchisee.name,
              FranName: this.franchisee.name,
              fee: this.caseObj.agencyFee,
              maid_name: this.maidObj.name,
              engName_page3_3: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agentName_page3_2: this.agent.agentName,
              date_page3_3: date,
              date_page3_4: date,
              date_page4: date,
              date_page4_2: date,
              eng_name_page4: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              agent_name_page4: this.agent.agentName,
              "maid_name_page4 copy": this.maidObj.name,
              "maid_nationality_page4 copy": this.maidObj.nationality,
              "maid_passport_page4 copy": this.maidObj.passportNum,
              eng_name_page3_2: this.maidObj.name,
            },
          ];

          break;
        case "id407":
          this.inputs = [
            {
              mask1: " ",
              "page3No1 copy 7": " ",
              address_notneeded: " ",
              "address_notneeded copy": " ",
              employerName: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerMiddleName} `,
              maidName: `${this.maidObj.LastNameOnIDPassport}  ${
                this.maidObj.FirstNameOnIDPassport
              } ${
                this.maidObj.MiddleNameOnIDPassport
                  ? this.maidObj.MiddleNameOnIDPassport
                  : ""
              }`,
              startdate: date,
              maidaddress: this.caseObj.id407.maidAddress,
              employerAddress: (this.caseObj.bio.customerAddress || this.caseObj.bio.customerAddress !== "") ? this.caseObj.bio.customerAddress : " ",
              wage: this.caseObj.id407.wage,
              allowance: this.caseObj.id407.allowance,
              date: date,
              date2: date,
              employerName2: `${this.caseObj.bio.customerLastName} ${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerMiddleName} `,
              maidName2: `${this.maidObj.LastNameOnIDPassport}  ${
                this.maidObj.FirstNameOnIDPassport
              } ${
                this.maidObj.MiddleNameOnIDPassport
                  ? this.maidObj.MiddleNameOnIDPassport
                  : ""
              }`,
              withness1: this.caseObj.id407.withness1,
              withness2: this.caseObj.id407.withness2,
              housesize: this.caseObj.id407.housesize,
              page4Other1: this.caseObj.id407.page4Other1,
              page4Other2: this.caseObj.id407.page4Other2,
              adultNum: this.caseObj.id407.adultNum,
              MinorNum: this.caseObj.id407.MinorNum,
              babies: this.caseObj.id407.babies,
              minor518: this.caseObj.id407.minor518,
              page3Yes: this.caseObj.id407.page3Yes ? "X" : " ",
              roomSize: this.caseObj.id407.roomSize,
              page3No: this.caseObj.id407.page3No ? "X" : " ",
              page3No1: this.caseObj.id407.page3No1 ? "X" : " ",
              page3No1ChildNum: this.caseObj.id407.page3No1ChildNum,
              page3No1ChildAge: this.caseObj.id407.page3No1ChildAge,
              page3No2: this.caseObj.id407.page3No2 ? "X" : " ",
              page3No2SQFT: this.caseObj.id407.page3No2SQFT,
              page3No3: this.caseObj.id407.page3No3 ? "X" : " ",
              page3No3DetailLine1: this.caseObj.id407.page3No3DetailLine1,
              page3No3DetailLine2: this.caseObj.id407.page3No3DetailLine2,
            },
          ];
          break;
        case "ofwInfo":
          this.inputs = [
            {
              lastName: this.maidObj.LastNameOnIDPassport,
              firstName: this.maidObj.FirstNameOnIDPassport,
              middleName: this.maidObj.MiddleNameOnIDPassport,
              passportNum: this.maidObj.passportNum,
              dob: momentTz(this.maidObj.dob, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              age: momentTz()
                .diff(
                  momentTz(this.maidObj.dob, "YYYY-MM-DD").format("YYYY"),
                  "years"
                )
                .toString(),
              new: this.caseObj.ofw.contractCategory === "New" ? "X" : "",
              recontract:
                this.caseObj.ofw.contractCategory === "Recontract" ? "X" : "",
              transfter:
                this.caseObj.ofw.contractCategory === "Transfter" ? "X" : "",
              finished:
                this.caseObj.ofw.contractCategory === "Finished" ? "X" : "",
              terminated:
                this.caseObj.ofw.contractCategory === "Terminated" ? "X" : "",
              contractNumber: this.caseObj.ofw.contractNumber,
              address: this.caseObj.bio.customerAddress,
              address2: "",
              OwwaNum: this.caseObj.ofw.OwwaNum,
              single: this.caseObj.ofw.maritalStatus === "Single" ? "X" : "",
              married: this.caseObj.ofw.maritalStatus === "Married" ? "X" : "",
              visaExpiresOn: this.caseObj.ofw.visaExpiresOn,
              passportValidUntil: this.caseObj.ofw.passportValidUntil,

              maidHKID: this.maidObj.idNumber,
              philippinesAddress: this.caseObj.id407 ? this.caseObj.id407.maidAddress : "",
              maidContactPerson: this.caseObj.ofw.maidContactPerson,
              maidNumber: this.caseObj.ofw.maidNumber,
              customerLastName: this.caseObj.bio.customerLastName,
              customerMiddleName: this.caseObj.bio.customerMiddleName,
              customerFirstName: this.caseObj.bio.customerFirstName,
              customerHKID: this.caseObj.ofw.customerHKID,
              customerPassportNum: this.caseObj.ofw.customerPassportNum,
              customerSpouseLastName: this.caseObj.ofw.customerSpouseLastName,
              customerSpouseFirstName: this.caseObj.ofw.customerSpouseFirstName,
              customerSpouseMiddleName:
                this.caseObj.ofw.customerSpouseMiddleName,
              customerSpouseHKID: this.caseObj.ofw.customerSpouseHKID,
              customerSpousePassportNum:
                this.caseObj.ofw.customerSpousePassportNum,
              hkAgency: this.franchisee.name,
              philippineAgency: "ANGELEX ALLIED AGENCY",
              hkAgencyPhone: this.franchisee.phoneNumber,
              maidPhone: this.caseObj.ofw.maidPhone,
              philippineAgencyPhone: "+63 9175085472",
              customerPhone: this.caseObj.bio.phoneNumber,
            },
          ];
          break;
        case "owwa":
          this.inputs = [
            {
              date2: date,
              lastName: this.maidObj.LastNameOnIDPassport,
              firstName: this.maidObj.FirstNameOnIDPassport,
              addressTop1: this.caseObj.owwa.addressTop1,
              addressTop2: this.caseObj.owwa.addressTop2,
              passportNum: this.maidObj.passportNum,
              phoneNum: this.caseObj.owwa.phoneNum,
              dob: momentTz(this.maidObj.dob, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              franName: "",
              customerName: `${this.caseObj.bio.customerFirstName} ${this.caseObj.bio.customerLastName}`,
              customerHKID: this.caseObj.owwa.customerHKID,
              customerAddress: this.caseObj.owwa.customerAddress,
              customerPhone: this.caseObj.bio.phoneNumber
                ? this.caseObj.bio.phoneNumber
                : "",
              amount: this.caseObj.owwa.amount,
              gender: this.caseObj.owwa.gender,
              religion: this.caseObj.owwa.religion,
              civistatus: this.caseObj.owwa.civistatus,
              position: this.caseObj.owwa.position,
              middleName: this.maidObj.MiddleNameOnIDPassport,
              suffix: this.caseObj.owwa.suffix,
              highestEduction: this.caseObj.owwa.highestEduction,
              course: this.caseObj.owwa.course,
              email: this.caseObj.owwa.email,
              certNum: " ",
              fullName1: this.caseObj.owwa.fullName1,
              fullName2: this.caseObj.owwa.fullName2,
              fullName0: this.caseObj.owwa.fullName0,
              relationship1: this.caseObj.owwa.relationship1,
              relationship2: this.caseObj.owwa.relationship2,
              relationship0: this.caseObj.owwa.relationship0,
              dob1: this.caseObj.owwa.dob1,
              dob2: this.caseObj.owwa.dob2,
              dob0: this.caseObj.owwa.dob0,
              address1: this.caseObj.owwa.address1,
              address2: this.caseObj.owwa.address2,
              address0: this.caseObj.owwa.address0,
              phone1: this.caseObj.owwa.phone1,
              phone2: this.caseObj.owwa.phone2,
              phone0: this.caseObj.owwa.phone0,
              agencyName: this.franchisee.name,
              hkPhoneNumber: this.caseObj.owwa.hkPhoneNumber,
            },
          ];
          break;
      }
    },
    async genPreview(id) {
      this.editFormId = id;
      switch (id) {
        case "serviceAgreementEng":
          this.template = serviceAgreementEng;
          break;
        case "serviceAgreementChinese":
          this.template = serviceAgreementChinese;
          break;
        case "contractAgencyER_Renewal_Eng":
          this.template = contractAgencyER_Renewal_Eng;
          break;
        case "contractAgencyER_Renewal_Chinese":
          this.template = contractAgencyER_Renewal_Chinese;
          break;
        case "serviceAgreementLocalEng":
          this.template = serviceAgreementLocalEng;
          break;
        case "serviceAgreementLocalChinese":
          this.template = serviceAgreementLocalChinese;
          break;
        case "serviceAgreementEREng":
          this.template = serviceAgreementEREng;
          break;
        case "serviceAgreementERChinese":
          this.template = serviceAgreementERChinese;
          break;
        case "owwa":
          if (!this.caseObj.owwa) {
            return alert(
              "Please Enter all the addtional fields from the edit fields before previewing this form"
            );
          }
          this.template = owwa;

          break;
        case "ofwInfo":
          if (!this.caseObj.ofw) {
            return alert(
              "Please Enter all the addtional fields from the edit fields before previewing this form"
            );
          }
          this.template = ofwInfo;
          break;
        case "id407":
          if (!this.caseObj.id407) {
            return alert(
              "Please Enter all the addtional fields from the edit fields before previewing this form"
            );
          }
          this.template = id407;
          break;
      }
      this.editMode = false;
      this.previewMode = true;

      this.genInput();
      let blob = await genContractPreviewBlob(this.inputs, this.template);
      this.showContract = true;
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.contractBlob = reader.result;
      };
    },
  },
  data() {
    return {
      downloadOnlyFile: downloadOnlyFile,
      tab: null,
      agent: {},
      franchisee: {},
      editFormObj: {},
      editFormId: null,
      editMode: false,
      previewMode: false,

      inputs: [],
      contractBlob: null,
      files: [
        {
          id: "serviceAgreementEng",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 (English)",
        },
        {
          id: "serviceAgreementChinese",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 (Chinese)",
        },
        {
          id: "contractAgencyER_Renewal_Eng",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 Renewal (English)",
        },
        {
          id: "contractAgencyER_Renewal_Chinese",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 Renewal (Chinese)",
        },
        {
          id: "serviceAgreementLocalEng",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 Local Renewal (English)",
        },
        {
          id: "serviceAgreementLocalChinese",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 Local Renewal (Chinese)",
        },
        {
          id: "serviceAgreementEREng",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 ER (English)",
        },
        {
          id: "serviceAgreementERChinese",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "服務協議 ER (Chinese)",
        },
        {
          id: "id407",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "Form id407",
        },
        {
          id: "owwa",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "OWWA",
        },
        {
          id: "ofwInfo",
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "",
          title: "OFW Information Sheet",
        },
      ],
    };
  },
};
</script>

<style>
</style>