<template>
  <div>
    <v-breadcrumbs
      :items="items"
      divider="/"
      large
    ></v-breadcrumbs>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm delete this match result?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog2 = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-card-title>
        Matched Results
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading='loading'
        loading-text="Loading data... Please wait"
        :headers="headers"
        :items="result"
        :search="search"
      >
        <template v-slot:item.id="{ item }">
          <a @click='()=> {$router.push(`/helper/matchedresult/${item.id}`)}'>{{item.id}}</a>
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>


<script>
import { db } from "../firebase/firebaseConfig";

export default {
  data: () => ({
    dialog2: false,
    loading: true,
    dialog: false,
    search: null,
    result: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Request Time", value: "time" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Match Result", value: "matchResultID" },
      { text: "Status", value: "status" },
    ],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Matched Results",
        disabled: true,
        href: "",
      },
    ],
  }),
  methods: {
    exportAll() {
      // Create link and download
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:application/json;charset=utf-8,%EF%BB%BF" +
          encodeURIComponent(JSON.stringify(this.result))
      );
      link.setAttribute("download", "helper");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async confirmDelete() {
      this.dialog2 = false;
      await db.collection("Helpers").doc(this.selectedDeleteItem).delete();
      this.selectedDeleteItem = null;
      alert("Profile Deleted");
    },
    async deleteItem(item) {
      this.selectedDeleteItem = item.id;
      this.dialog2 = true;
    },
  },
  async mounted() {
    db.collection("MatchResultsWithContactInfo")
      .orderBy("time", "desc")
      .onSnapshot((docs) => {
        this.result = docs.docs.map((doc) => doc.data());
        this.loading = false;
      });
  },
};
</script>
