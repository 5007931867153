<template>
  <div>
    <v-layout
      class='mb-10'
      align-center
    >
      <v-flex>
        <v-img
          v-if='franImg'
          class="ml-3"
          contain
          height="120px"
          position="center"
          :src="`data:image/png;base64,${franImg}`"
        ></v-img>
      </v-flex>
    </v-layout>
    <v-card>
      <v-card-title>
        <span class="text-h5">Self Register</span>
        <v-spacer></v-spacer>
        <v-btn @click='upload'>Upload Photo</v-btn>
        <input
          id='fileid'
          type='file'
          @change="Handlechange"
          hidden
        />
        <img
          v-if='base64'
          height='150'
          :src="`data:image/png;base64,${base64}`"
        />
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
          
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  label="Video Link"
                  v-model="formData.videoLink"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                dense
                  :rules="requiredRule"
                  :items="['Finished Contract','Special Case', 'Term/Break','Oversea']"
                  label="Job Status*"
                  required
                  v-model="formData.jobstatus"
                  @change='onJobStatusChange'
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                dense
                  disabled
                  :items="['Local']"
                  label="Status"
                  v-model="formData.status"
                  required
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Name*"
                  dense
                  :rules="requiredRule"
                  v-model="formData.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone Number*"
                  dense
                  :rules="requiredRule"
                  v-model="formData.phoneNumber"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-dialog
                  ref="dialog3"
                  v-model="modal3"
                  
                  :return-value.sync="formData.dob"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    dense
                      v-model="formData.dob"
                      label="Date of Birth*"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.dob"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal3 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog3.save(formData.dob)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Age*"
                  dense
                  :rules="requiredRule"
                  v-model="formData.age"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  :return-value.sync="formData.availabilityDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    dense
                      v-model="formData.availabilityDate"
                      label="Availabile on"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.availabilityDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog2.save(formData.availabilityDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12">
                <v-select
                dense
                  label="Nationality*"
                  :items="['Filipino','Indonesian','Others']"
                  :rules="requiredRule"
                  v-model="formData.nationality"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="['no preference','1-2','3-4','5+']"
                  label="Preferred Family Size"
                  v-model="formData.preferredfamilysize"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                dense
                  :items="['Junior High','High School','College','Others']"
                  label="Education*"
                  :rules="requiredRule"
                  v-model="formData.education"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                dense
                  :items="['Single','Married','Separated','Others']"
                  v-model="formData.maritalStatus"
                  label="Marital Status*"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  required
                  dense
                  :rules="requiredRule"
                  label="Number of children and age*"
                  v-model="formData.childenNumAndAge"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  dense
                  :rules="requiredRule"
                  label="Number of siblings and ranking*"
                  v-model="formData.siblingsNumAndRanking"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                dense
                  :items="['Christian','Roman Catholic','Protestantism','Muslim','Others']"
                  label="Religion*"
                  v-model="formData.religion"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-text-field
                dense
                  label="Height*"
                  suffix="cm/inch"
                  required
                  :rules="requiredRule"
                  v-model="formData.height"
                ></v-text-field>
              </v-col>
              <v-col cols='12'>
                <v-text-field
                dense
                  label="Weight*"
                  suffix="kg"
                  v-model="formData.weight"
                  required
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Cantonese Skill*"
                  v-model="formData.cantonese"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="English Skill*"
                  v-model="formData.english"
                  required
                  :rules="requiredRule"
                ></v-select>

              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Mandarin Skill*"
                  v-model="formData.mandarin"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Infant"
                  v-model="formData.careInfant"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Child"
                  v-model="formData.careChild"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Elderly"
                  v-model="formData.careElderly"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Disabled Person"
                  v-model="formData.careDisabled"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Bedridden Person"
                  v-model="formData.careBedridden"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Care of Pet"
                  v-model="formData.carePet"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Cooking"
                  v-model="formData.cooking"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="General Housework"
                  v-model="formData.generalHousework"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                dense
                  :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                  item-text="text"
                  item-value="val"
                  label="Car Washing"
                  v-model="formData.carWashing"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Cleanliness"
                  v-model="formData.cleanliness"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Discipline"
                  v-model="formData.discipline"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Empathy"
                  v-model="formData.empathy"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Efficiency"
                  v-model="formData.efficiency"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Friendliness"
                  v-model="formData.friendliness"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Hardworking"
                  v-model="formData.hardworking"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Honesty"
                  v-model="formData.honesty"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Patience"
                  v-model="formData.patience"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Proactiveness"
                  v-model="formData.proactiveness"
                  required
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                dense
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  label="Responsible"
                  v-model="formData.responsible"
                  required
                ></v-select>
              </v-col>

              <v-col cols='12'>
                <span class="text-h6">General Information</span>
              </v-col>

              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Eat Pork"
                  v-model="formData.pork"
                  :rules="requiredRule"
                  required
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Accept day-off not on Sunday"
                  v-model="formData.acceptNoSundayOff"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Sharing room with babies/children/elderly"
                  v-model="formData.roomShare"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Afraid of pet"
                  v-model="formData.afraidPet"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Smoke"
                  v-model="formData.smoke"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Drink alcohol"
                  v-model="formData.alcohol"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Any prolonged illnessess/undergone surgery"
                  v-model="formData.illnessesSurgery"
                  required
                  :rules="requiredRule"
                ></v-select>
              </v-col>
              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="Are you willing to share job with other helpers?"
                  :rules="requiredRule"
                  v-model="formData.shareJob"
                ></v-select>
              </v-col>

              <v-col cols='12'>
                <v-select
                dense
                  :items="['Yes','No']"
                  label="CCTV ok?"
                  :rules="requiredRule"
                  v-model="formData.ccTvOk"
                ></v-select>
              </v-col>

              <v-col cols='12'>
                <span class="text-h6">
                  Domestic Helper Employment Record</span>
              </v-col>

              <v-col cols='12'>
                <v-row>
                  <v-col
                    cols='3'
                    style='margin-top : 60px'
                  > 地區工作經驗 <br />
                    Country Experience (Years + Months)
                  </v-col>
                  <v-col cols='9'>
                    <v-row>
                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Hong Kong"
                          v-model="formData.workRecord.hk"
                        ></v-text-field>
                      </v-col>
                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Middle East"
                          v-model="formData.workRecord.middleEast"
                        ></v-text-field>
                      </v-col>
                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Singapore"
                          v-model="formData.workRecord.singapore"
                        ></v-text-field>
                      </v-col>

                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Taiwan"
                          v-model="formData.workRecord.taiwan"
                        ></v-text-field>
                      </v-col>
                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Indonesia"
                          v-model="formData.workRecord.indonesia"
                        ></v-text-field>
                      </v-col>

                      <v-col cols='6'>
                        <v-text-field
                          dense
                          label="Philippines"
                          v-model="formData.workRecord.philippines"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12'>
                <span class="text-h6">
                  Working Exp as Domestic Helper</span>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Brief Intro"
                  v-model='formData.briefInfo'
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <p>Work Exp (Limit to Most Recent 3 Jobs)</p>
               
                <v-card
                  v-for="(exp,index) in workExp"
                  :key="index"
                >
                  <div class='pa-5 mb-3 mt-3'>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                        dense
                          label="Working Exp Ex:(2015) "
                          v-model="workExp[index].duration"
                          required
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                        dense
                          :items="['Philippines','Indonesia','Hong Kong','Singapore','Taiwan','Middle East','Others']"
                          label="Country"
                          v-model="workExp[index].country"
                          required
                          :rules="requiredRule"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if='workExp[index].country === "Hong Kong"'
                      >
                        <v-text-field
                        dense
                          label="Districts in HK"
                          required
                          :rules="requiredRule"
                          v-model="workExp[index].hongKongLocation"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                        dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="# of Employer's Family Members"
                          v-model="workExp[index].houseHoldSize"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                        
                          dense
                          label="House Size (SQFT)"
                          v-model="workExp[index].housesize"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Nationality of Employer"
                          v-model="workExp[index].employerNationality"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Reason for leaving"
                          v-model="workExp[index].reasonToLeave"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Infant"
                          v-model="workExp[index].careInfant"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail (ex: 1, 6 MO Boy)"
                          v-model="workExp[index].careInfantDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Child"
                          v-model="workExp[index].careChild"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail (ex: 2, 3YO Boy, 6YO Girl)"
                          v-model="workExp[index].careChildDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Elderly"
                          v-model="workExp[index].careElderly"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail (ex: 2, 96YO male, 87YO female)"
                          v-model="workExp[index].careElderlyDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Disabled Person"
                          v-model="workExp[index].careDisabled"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail (ex: on wheelchair)"
                          v-model="workExp[index].careDisabledDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Bedridden Person"
                          v-model="workExp[index].careBedridden"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Care of Pet"
                          v-model="workExp[index].carePet"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail (ex: 6 cats, 3 dogs)"
                          v-model="workExp[index].carePetDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="['Yes','No']"
                          label="Cooking"
                          v-model="workExp[index].cooking"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Addt Detail(ex: chin, western)"
                          v-model="workExp[index].cookingDetail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="General Housework"
                          v-model="workExp[index].generalHousework"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Car Washing"
                          v-model="workExp[index].carWashing"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      style='color : red'
                      @click="removeWorkexp(index)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>

                 <v-btn
                 class='mt-5'
                  color="secondary"
                  dark
                  @click="addWorkExp"
                >
                  Add Working Experience
                </v-btn>

              </v-col>
              <v-checkbox
                required
                :rules="agreeRule"
                v-model="privacyNotice"
              >
                <template v-slot:label>
                  <div>

                    I have read and understand the
                    <a
                      target="_blank"
                      href="https://storage.googleapis.com/starjasmine-aacba.appspot.com/registerNotice.pdf"
                      @click.stop
                    >
                      privacy notice
                    </a>

                  </div>
                </template>
              </v-checkbox>
            </v-row>
          </v-form>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :loading='regLoading'
          @click="submitData"
        >
          Register
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
    </v-card>
  </div>
</template>



<script>
import axios from "axios";
import { getFranImg } from "../../dataservices/regDataservices";
export default {
  async mounted() {
    this.franImg = await getFranImg(this.$route.query.id);
    this.formData.franchiseeId = this.$route.query.id
      ? this.$route.query.id
      : "";
    this.addWorkExp();
    this.addWorkExp();
    this.addWorkExp();
  },
  data: () => ({
    franImg: "",
    agreeRule: [(v) => !!v || "You must agree to continue!"],
    regLoading: false,
    privacyNotice: false,
    fileName: null,
    fileType: null,
    uploading: false,
    base64: "",
    modal: false,
    modal2: false,
    modal3: false,
    valid: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    search: null,
    result: [],
    requiredRule: [(v) => !!v || "This Field is required"],
    workExp: [],
    formData: {
      franchiseeId: "",
      workRecord: {
        hk: null,
        middleEast: null,
        singapore: null,
        taiwan: null,
        indonesia: null,
        philippines: null,
      },
      ccTvOk: 'Yes',
      shareJob: 'Yes',
      siblingsNumAndRanking: null,
      childenNumAndAge: null,
      jobstatus: 'Finished Contract',
      phoneNumber: null,
      fileLocation: null,
      portfolioId: null,
      submitDate: null,
      availabilityDate: null,
      valid: true,
      name: null,
      dob: null,
      age: 0,
      nationality: null,
      education: 'High School',
      status: "Local",
      maritalStatus: 'Single',
      religion: null,
      height: 0,
      weight: 0,
      pic: null,
      videoLink: null,
      briefInfo: null,
      preferredfamilysize: 'no preference',
      cleanliness: 10,
      discipline: 10,
      empathy: 10,
      efficiency: 10,
      friendliness: 10,
      hardworking: 10,
      honesty: 10,
      patience: 10,
      proactiveness: 10,
      responsible: 10,
      careInfant: 3,
      careChild: 3,
      careElderly: 3,
      careDisabled: 3,
      careBedridden: 3,
      carePet: 3,
      cooking: 3,
      generalHousework: 3,
      carWashing: 3,
      cantonese: 2,
      english: 4,
      mandarin: 2,
      pork: 'Yes',
      acceptNoSundayOff: 'Yes',
      roomShare: 'Yes',
      afraidPet: 'No',
      smoke: 'No',
      alcohol: 'No',
      illnessesSurgery: 'No',
    },
  }),
  methods: {
    onJobStatusChange(v) {
      switch (v) {
        case "Finished Contract":
        case "Special Case":
          this.formData.status = "Local";
          break;
        default:
          this.formData.status = "Oversea";
      }
    },
    async Handlechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.base64 = image;
        this.base64 = this.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;
      };
      reader.readAsDataURL(file);
    },
    upload() {
      document.getElementById("fileid").click();
    },
    bindData(data) {
      for (const property in this.formData) {
        this.formData[property] = data[property];
      }
      this.workExp = data.workExp;
    },
    removeWorkexp(index) {
      this.workExp.splice(index, 1);
    },
    addWorkExp() {
      this.workExp.push({
        hongKongLocation: null,
        employerNationality: null,
        duration: 0,
        country: null,
        houseHoldSize: 0,
        reasonToLeave: null,
        otherSkill: {
          careInfantDetail: null,
          careInfant: false,
          careChildDetail: null,
          careChild: false,
          careElderly: false,
          careElderlyDetail: null,
          careDisabled: false,
          careDisabledDetail: null,
          careBedridden: false,
          carePet: false,
          carePetDetail: null,
          cooking: false,
          cookingDetail: null,
          generalHousework: false,
          carWashing: false,
        },
      });
    },
    async submitData() {
      let options = {
        "content-type": "text/json",
        "Access-Control-Allow-Origin": "*",
      };
      let valid = this.$refs.form.validate();
      if (valid) {
        this.regLoading = true;
        let image = {
          fileName: this.fileName,
          baseString: this.base64,
          fileType: this.fileType,
        };
        await axios.post(
          "https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/register",
          {
            data: this.formData,
            workExp: this.workExp,
            image: image,
          },
          options
        );
        this.regLoading = false;
        alert(
          "Thank you for registering with us, we will review your infomation and get back to you as soon as possible."
        );
        location.reload();
      } else {
        return alert("Please fill in all required fields");
      }
    },
  },
};
</script>
