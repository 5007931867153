<template>
    <div>
    
        <v-breadcrumbs :items="items" divider="/" large></v-breadcrumbs>
    
    
        <v-form
  >
    <v-text-field
      v-model="email"
      :value='email'
      label="E-mail"
      readonly
    ></v-text-field>

        <v-text-field
      v-model="phone"
      label="Phone"
      readonly
    ></v-text-field>
      <v-text-field
      v-model="prefix"
      label="Prefix"
      readonly
    ></v-text-field>
    <v-text-field
      v-model="lastName"
      label="Last Name"
      readonly
    ></v-text-field>

    <v-btn
      color="primary"
      class="mr-4"
      @click="review"
    >
      Reviewed
    </v-btn>
    <p>System returned result id:   <a @click='()=> {$router.push(`/matchresult/${resultID}`)}'>{{resultID}}</a></p>
        <p>Language User Selected: {{language}}</p>
  </v-form>
    
    
    
    </div>
</template>

<script>
import { db } from '../firebase/firebaseConfig'

export default {
    methods : {
        review(){
            db.collection('NoResultContactInfo').doc(this.$route.params.id).update({
                status : 'reviewed'
            })
        }
    },
    mounted() {

        db.collection('NoResultContactInfo').where('id', '==', this.$route.params.id).onSnapshot(docs => {
            this.result = docs.docs.map(doc => doc.data())[0];
            this.email =  this.result.email
            this.phone = this.result.phone;
            this.prefix = this.result.prefix;
            this.lastName = this.result.lastName;
            this.resultID = this.result.result;
                        this.language = this.result.language;

        })

    },
    data() {
        return {
             language : '',
            resultID : '',
            prefix: '',
            lastName : '',
            email : '',
            phone : '',
            result: null,
            items: [{
                    text: 'Home',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'No Match Results',
                    disabled: false,
                    href: '/noResultfollowup',
                },
                {
                    text: `No Match Result: ${this.$route.params.id}`,
                    disabled: true,
                    href: '',
                }
            ],
        }

    }
}
</script>